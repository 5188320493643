<template>
  <v-container class="pt-0" v-if="!$store.state.messages.loading">
    <v-card class="elevation-12 mx-auto" max-width="650">
      <v-toolbar dark color="user_primary">
        <v-toolbar-title class="text-sm-h5 mx-auto"
          >Contact Hairdreams</v-toolbar-title
        >
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-text-field
                auto-resize
                hide-details
                label="First name"
                v-model="message.firstName"
              >
              </v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                hide-details
                label="Last name"
                v-model="message.lastName"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <VuePhoneNumberInput
                show-code-on-list
                @update="handleSmsPhoneUpdate"
                v-model="message.smsPhone.phoneNumber"
                no-example
                default-country-code="US"
                :only-countries="['US', 'CA']"
                color="#222"
                valid-color="#222"
                error-color="red"
                height="100"
                :size="$vuetify.breakpoint.smAndDown ? 'sm' : 'lg'"
                :no-flags="$vuetify.breakpoint.smAndDown ? true : false"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="9">
              <v-text-field
                hide-details
                label="Email"
                v-model="message.email"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                hide-details
                label="Zip"
                v-model="message.zip"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="isDreamweftsfUrl" class="mt-5 mb-3">
            <v-card-text class="body-1">
              Send me information about Hairdreams
              <span class="font-weight-bold">DreamWefts</span>.</v-card-text
            >
          </v-row>
          <v-row v-if="isUltraUrl" class="mt-5 mb-3">
            <v-card-text class="body-1"
              >Send me information about Hairdreams
              <span class="font-weight-bold">Laserbeamer ULTRA</span
              >.</v-card-text
            >
          </v-row>

          <v-row class="justify-center py-0 align-start">
            <v-col cols="12" class="py-0 pb-0 d-flex align-start">
              <v-checkbox
                class="checkbox pt-0 mt-0"
                v-model="message.notice"
                :ripple="false"
              />

              <v-col class="pa-0" style="line-height: 14px">
                <v-card-text class="pa-0 pb-1 checkbox body-1 grey-color"
                  >I understand the
                  <a
                    href="https://iwanthairdreams.com/pages/notice-of-collection-of-personal-information"
                    target="_blank"
                  >
                    Notice of Collection of Personal Information</a
                  >
                </v-card-text>
              </v-col>
            </v-col>
          </v-row>
          <v-card-actions class="pr-0 pb-0">
            <v-spacer></v-spacer>
            <v-btn
              class="white--text"
              rounded
              color="user_primary"
              @click="handleSend"
              :disabled="!isValid"
              :loading="isSending"
              >Send
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card-text>
    </v-card>

    <SimpleModal
      :showDialog="responseDialog"
      :message="responseMessage"
      @closeSimpleModal="closeResponseDialog"
    />
  </v-container>
</template>

<script>
  import {
    ContactMessageStylistTopics,
    MessageFrom,
    MessageTypes,
    ThankYouPageSources
  } from '@/config/enums'
  import { mapActions } from 'vuex'

  export default {
    data() {
      return {
        ContactMessageStylistTopics,
        isSending: false,
        responseMessage: '',
        responseDialog: false,
        message: {
          type: MessageTypes.ST_H,
          from: MessageFrom.ST,
          source: '',
          topic: '',
          firstName: '',
          lastName: '',
          smsPhone: { phoneNumber: '', formattedNumber: '', validated: false },
          email: '',
          stylist: '',
          body: '',
          zip: '',
          sendPromotion: false
        }
      }
    },
    computed: {
      isDreamweftsfUrl() {
        return (
          this.urlType.toLowerCase() ===
          this.ContactMessageStylistTopics.DREAMWEFTS.toLowerCase()
        )
      },
      isUltraUrl() {
        return (
          this.urlType.toLowerCase() ===
          this.ContactMessageStylistTopics.ULTRA.toLowerCase()
        )
      },
      urlType() {
        return this.$route.params.type || ''
      },
      isValid() {
        return (
          !!this.message.firstName &&
          !!this.message.lastName &&
          !!this.message.smsPhone.phoneNumber &&
          !!this.message.email &&
          !!this.message.type &&
          !!this.message.body &&
          !!this.message.topic &&
          !!this.message.zip &&
          !!this.message.notice
        )
      }
    },
    methods: {
      ...mapActions(['send_st_h']),
      handleSmsPhoneUpdate(obj) {
        this.message.smsPhone.formattedNumber = obj.formattedNumber
      },
      closeResponseDialog() {
        this.responseDialog = false
        this.onClose()
      },

      async handleSend() {
        if (this.isValid) {
          this.isSending = true
          // don't send empty consumer
          if (!this.message.consumer) {
            delete this.message.consumer
          }
          await this.send_st_h(this.message)
          if (!this.$store.state.messages.error) {
            this.responseMessage = 'Message has been sent!'
            this.responseDialog = true
            this.dialog = false
            this.$router.replace({
              name: 'ThankYouPage',
              params: { source: ThankYouPageSources.contact },
              query: this.$route.query
            })
          }
          this.isSending = false
        }
      }
    },

    created() {
      if (!this.isUltraUrl && !this.isDreamweftsfUrl) {
        // note: to go to other contact page and copy query params
        this.$router.replace({
          name: 'stylist-other-contact',
          query: this.$route.query
        })
      } else if (this.isDreamweftsfUrl) {
        this.message.topic = this.ContactMessageStylistTopics.DREAMWEFTS
        this.message.body = 'Send me information about Hairdreams DreamWefts.'
      } else if (this.isUltraUrl) {
        this.message.topic = this.ContactMessageStylistTopics.ULTRA
        this.message.body =
          'Send me information about Hairdreams Laserbeamer ULTRA.'
      }
      this.message.source = this.$route.query.source || ''
    }
  }
</script>
