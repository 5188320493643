<template>
  <v-card class="elevation-0 mx-auto pa-3" max-width="450">
    <v-card-text>
      <v-row class="justify-center">
        <v-row>
          <v-col cols="12" class="pl-0 pb-0">
            <v-card-title class="breakword pb-0"
              >Select a system you are interested in</v-card-title
            ></v-col
          >
          <v-col
            class="d-flex justify-center flex-column align-center system-img pb-0"
            cols="6"
            @click="handleSelect(SystemOptions.LASERBEAMER)"
          >
            <v-img lazy-src="@/assets/NANO.jpg" src="@/assets/NANO.jpg" />
            <v-btn
              depressed
              :color="
                answer === SystemOptions.LASERBEAMER ? 'user_cta' : 'user_btn'
              "
              :dark="answer === SystemOptions.LASERBEAMER"
              class="text-none mt-1"
              width="100%"
              >Laserbeamer Nano</v-btn
            >
            <v-card-text class="text-center pt-0 px-0 btn-caption" caption
              >Automatic bonded extensions</v-card-text
            > </v-col
          ><v-col
            class="d-flex justify-center flex-column align-center system-img pb-0"
            cols="6"
            @click="handleSelect(SystemOptions.FREESTYLER)"
          >
            <v-img
              lazy-src="@/assets/FREESTYLER.jpg"
              src="@/assets/FREESTYLER.jpg"
            />
            <v-btn
              depressed
              :color="
                answer === SystemOptions.FREESTYLER ? 'user_cta' : 'user_btn'
              "
              :dark="answer === SystemOptions.FREESTYLER"
              class="text-none mt-1"
              width="100%"
              >FreeStyler</v-btn
            >
            <v-card-text class="text-center pt-0 px-0 btn-caption" caption
              >Custom bonded extensions</v-card-text
            >
          </v-col>
          <v-col
            class="d-flex justify-center flex-column align-center system-img py-0"
            cols="6"
            @click="handleSelect(SystemOptions.TAPE)"
          >
            <v-img lazy-src="@/assets/TAPES.jpg" src="@/assets/TAPES.jpg" />

            <v-btn
              depressed
              :color="answer === SystemOptions.TAPE ? 'user_cta' : 'user_btn'"
              :dark="answer === SystemOptions.TAPE"
              class="text-none mt-1"
              width="100%"
              >Tape-Ins</v-btn
            >
            <v-card-text class="text-center pt-0 px-0 btn-caption" caption
              >Featuring BABY SECRETS</v-card-text
            >
          </v-col>
          <v-col
            class="d-flex justify-center flex-column align-center system-img py-0"
            cols="6"
            @click="handleSelect(SystemOptions.MICROLINES)"
          >
            <v-img
              lazy-src="@/assets/MICROLINES.jpg"
              src="@/assets/MICROLINES.jpg"
            />
            <v-btn
              depressed
              :color="
                answer === SystemOptions.MICROLINES ? 'user_cta' : 'user_btn'
              "
              :dark="answer === SystemOptions.MICROLINES"
              class="text-none mt-1"
              width="100%"
              >MicroLines</v-btn
            ><v-card-text class="text-center pt-0 px-0 btn-caption" caption
              >Immediate hair loss solution</v-card-text
            ></v-col
          >
        </v-row>
      </v-row>
      <!-- 
      <v-row class="justify-center">
        <v-btn
          @click="handleOtherSystem"
          text
          color="blue"
          class="text-none mt-3 text-decoration-underline"
          >I am not inquiring about any systems</v-btn
        >
      </v-row> -->
    </v-card-text>
  </v-card>
</template>

<script>
  import { SystemOptions, getShortSystemForUrl } from '@/config/enums'
  import { mapActions } from 'vuex'

  export default {
    data() {
      return {
        SystemOptions,
        getShortSystemForUrl,
        screen: 'stylist-application-primary-system',
        nextScreen: 'stylist-additional-info',
        answer: '',
        field: 'primarySystem'
      }
    },
    methods: {
      ...mapActions([
        'setApplicationData',
        'setCurrentScreen',
        'setNextScreen',
        'setIsValid'
      ]),
      handleNextScreen() {
        this.setNextScreen(this.nextScreen)
      },
      async handleSelect(answer) {
        this.answer = answer

        // 1. set data
        this.setApplicationData({ field: this.field, answer })
        // 2. set next screen depending on this component logic
        this.handleNextScreen()
        // 3. set valid depending on this component logic
        this.setIsValid(true)
        // 4. go to next screen
        this.$emit('next')
      }
    },
    created() {
      // 1. set current screen
      this.setCurrentScreen(this.screen)
      // 2. set answer, sent next screen and valid if data exists
      if (
        this.$store.state.applications.application[this.field] &&
        this.$store.state.applications.application[this.field].length > 0
      ) {
        this.answer = this.$store.state.applications.application[this.field]
        this.handleNextScreen()
        this.setIsValid(true)
      }
      // 3. set valid if data doesn't exist
      else {
        this.setIsValid(false)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .system-img {
    cursor: pointer;
  }
  .btn-caption {
    color: #7d7d7d;
    font-size: 10px;
  }
  .breakword {
    word-break: break-word;
  }
</style>
