var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"elevation-1 pa-5",attrs:{"fluid":""}},[(_vm.isLoading)?_c('div',{staticClass:"d-flex justify-center",staticStyle:{"min-height":"1000px"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":70,"width":7,"color":"purple"}})],1):[_c('v-snackbar',{attrs:{"centered":"","top":"","color":_vm.snackbarColor,"timeout":_vm.snackbarTimeout},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.notification))]),_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"4","lg":"2"}},[_c('v-card',[_c('v-card-title',[_vm._v("LB - FULL HEAD")]),_c('v-card-text',[_c('v-text-field',{attrs:{"outlined":"","label":"Promo price"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.LB_FULL].promoPrice
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.LB_FULL], "promoPrice", _vm._n($$v))},expression:"\n                localSettings[StylistsCertifications.LB_FULL].promoPrice\n              "}}),_c('v-text-field',{attrs:{"outlined":"","label":"List price"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.LB_FULL].listPrice
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.LB_FULL], "listPrice", _vm._n($$v))},expression:"\n                localSettings[StylistsCertifications.LB_FULL].listPrice\n              "}}),_c('v-textarea',{attrs:{"outlined":"","label":"Paragraph"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.LB_FULL].paragraph
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.LB_FULL], "paragraph", $$v)},expression:"\n                localSettings[StylistsCertifications.LB_FULL].paragraph\n              "}}),_c('v-text-field',{attrs:{"outlined":"","label":"Primary Button text"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.LB_FULL].buttonText
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.LB_FULL], "buttonText", $$v)},expression:"\n                localSettings[StylistsCertifications.LB_FULL].buttonText\n              "}}),_c('v-text-field',{attrs:{"outlined":"","label":"Primary Button link"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.LB_FULL].buttonLink
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.LB_FULL], "buttonLink", $$v)},expression:"\n                localSettings[StylistsCertifications.LB_FULL].buttonLink\n              "}}),_c('v-text-field',{attrs:{"outlined":"","label":"Secondary Button text"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.LB_FULL]
                  .secondaryButtonText
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.LB_FULL]
                  , "secondaryButtonText", $$v)},expression:"\n                localSettings[StylistsCertifications.LB_FULL]\n                  .secondaryButtonText\n              "}}),_c('v-text-field',{attrs:{"outlined":"","label":"Secondary Button link"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.LB_FULL]
                  .secondaryButtonLink
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.LB_FULL]
                  , "secondaryButtonLink", $$v)},expression:"\n                localSettings[StylistsCertifications.LB_FULL]\n                  .secondaryButtonLink\n              "}}),_c('v-switch',{staticClass:"mt-0",attrs:{"label":"Show Secondary Button"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.LB_FULL]
                  .showSecondaryButton
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.LB_FULL]
                  , "showSecondaryButton", $$v)},expression:"\n                localSettings[StylistsCertifications.LB_FULL]\n                  .showSecondaryButton\n              "}})],1)],1)],1),_c('v-col',{attrs:{"cols":"6","md":"4","lg":"2"}},[_c('v-card',[_c('v-card-title',[_vm._v("LB - HALF HEAD")]),_c('v-card-text',[_c('v-text-field',{attrs:{"outlined":"","label":"Promo price"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.LB_HALF].promoPrice
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.LB_HALF], "promoPrice", _vm._n($$v))},expression:"\n                localSettings[StylistsCertifications.LB_HALF].promoPrice\n              "}}),_c('v-text-field',{attrs:{"outlined":"","label":"List price"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.LB_HALF].listPrice
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.LB_HALF], "listPrice", _vm._n($$v))},expression:"\n                localSettings[StylistsCertifications.LB_HALF].listPrice\n              "}}),_c('v-textarea',{attrs:{"outlined":"","label":"Paragraph"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.LB_HALF].paragraph
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.LB_HALF], "paragraph", $$v)},expression:"\n                localSettings[StylistsCertifications.LB_HALF].paragraph\n              "}}),_c('v-text-field',{attrs:{"outlined":"","label":"Primary Button text"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.LB_HALF].buttonText
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.LB_HALF], "buttonText", $$v)},expression:"\n                localSettings[StylistsCertifications.LB_HALF].buttonText\n              "}}),_c('v-text-field',{attrs:{"outlined":"","label":"Primary Button link"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.LB_HALF].buttonLink
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.LB_HALF], "buttonLink", $$v)},expression:"\n                localSettings[StylistsCertifications.LB_HALF].buttonLink\n              "}}),_c('v-text-field',{attrs:{"outlined":"","label":"Secondary Button text"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.LB_HALF]
                  .secondaryButtonText
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.LB_HALF]
                  , "secondaryButtonText", $$v)},expression:"\n                localSettings[StylistsCertifications.LB_HALF]\n                  .secondaryButtonText\n              "}}),_c('v-text-field',{attrs:{"outlined":"","label":"Secondary Button link"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.LB_HALF]
                  .secondaryButtonLink
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.LB_HALF]
                  , "secondaryButtonLink", $$v)},expression:"\n                localSettings[StylistsCertifications.LB_HALF]\n                  .secondaryButtonLink\n              "}}),_c('v-switch',{staticClass:"mt-0",attrs:{"label":"Show Secondary Button"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.LB_HALF]
                  .showSecondaryButton
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.LB_HALF]
                  , "showSecondaryButton", $$v)},expression:"\n                localSettings[StylistsCertifications.LB_HALF]\n                  .showSecondaryButton\n              "}})],1)],1)],1),_c('v-col',{attrs:{"cols":"6","md":"4","lg":"2"}},[_c('v-card',[_c('v-card-title',[_vm._v("FS - IN PERSON")]),_c('v-card-text',[_c('v-text-field',{attrs:{"outlined":"","label":"Promo price"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.FS_IN_PERSON].promoPrice
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.FS_IN_PERSON], "promoPrice", _vm._n($$v))},expression:"\n                localSettings[StylistsCertifications.FS_IN_PERSON].promoPrice\n              "}}),_c('v-text-field',{attrs:{"outlined":"","label":"List price"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.FS_IN_PERSON].listPrice
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.FS_IN_PERSON], "listPrice", _vm._n($$v))},expression:"\n                localSettings[StylistsCertifications.FS_IN_PERSON].listPrice\n              "}}),_c('v-textarea',{attrs:{"outlined":"","label":"Paragraph"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.FS_IN_PERSON].paragraph
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.FS_IN_PERSON], "paragraph", $$v)},expression:"\n                localSettings[StylistsCertifications.FS_IN_PERSON].paragraph\n              "}}),_c('v-text-field',{attrs:{"outlined":"","label":"Primary Button text"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.FS_IN_PERSON].buttonText
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.FS_IN_PERSON], "buttonText", $$v)},expression:"\n                localSettings[StylistsCertifications.FS_IN_PERSON].buttonText\n              "}}),_c('v-text-field',{attrs:{"outlined":"","label":"Primary Button link"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.FS_IN_PERSON].buttonLink
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.FS_IN_PERSON], "buttonLink", $$v)},expression:"\n                localSettings[StylistsCertifications.FS_IN_PERSON].buttonLink\n              "}}),_c('v-text-field',{attrs:{"outlined":"","label":"Secondary Button text"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.FS_IN_PERSON]
                  .secondaryButtonText
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.FS_IN_PERSON]
                  , "secondaryButtonText", $$v)},expression:"\n                localSettings[StylistsCertifications.FS_IN_PERSON]\n                  .secondaryButtonText\n              "}}),_c('v-text-field',{attrs:{"outlined":"","label":"Secondary Button link"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.FS_IN_PERSON]
                  .secondaryButtonLink
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.FS_IN_PERSON]
                  , "secondaryButtonLink", $$v)},expression:"\n                localSettings[StylistsCertifications.FS_IN_PERSON]\n                  .secondaryButtonLink\n              "}}),_c('v-switch',{staticClass:"mt-0",attrs:{"label":"Show Secondary Button"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.FS_IN_PERSON]
                  .showSecondaryButton
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.FS_IN_PERSON]
                  , "showSecondaryButton", $$v)},expression:"\n                localSettings[StylistsCertifications.FS_IN_PERSON]\n                  .showSecondaryButton\n              "}})],1)],1)],1),_c('v-col',{attrs:{"cols":"6","md":"4","lg":"2"}},[_c('v-card',[_c('v-card-title',[_vm._v("FS - ONLINE")]),_c('v-card-text',[_c('v-text-field',{attrs:{"outlined":"","label":"Promo price"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.FS_ONLINE].promoPrice
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.FS_ONLINE], "promoPrice", _vm._n($$v))},expression:"\n                localSettings[StylistsCertifications.FS_ONLINE].promoPrice\n              "}}),_c('v-text-field',{attrs:{"outlined":"","label":"List price"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.FS_ONLINE].listPrice
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.FS_ONLINE], "listPrice", _vm._n($$v))},expression:"\n                localSettings[StylistsCertifications.FS_ONLINE].listPrice\n              "}}),_c('v-textarea',{attrs:{"outlined":"","label":"Paragraph"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.FS_ONLINE].paragraph
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.FS_ONLINE], "paragraph", $$v)},expression:"\n                localSettings[StylistsCertifications.FS_ONLINE].paragraph\n              "}}),_c('v-text-field',{attrs:{"outlined":"","label":"Primary Button text"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.FS_ONLINE].buttonText
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.FS_ONLINE], "buttonText", $$v)},expression:"\n                localSettings[StylistsCertifications.FS_ONLINE].buttonText\n              "}}),_c('v-text-field',{attrs:{"outlined":"","label":"Primary Button link"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.FS_ONLINE].buttonLink
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.FS_ONLINE], "buttonLink", $$v)},expression:"\n                localSettings[StylistsCertifications.FS_ONLINE].buttonLink\n              "}}),_c('v-text-field',{attrs:{"outlined":"","label":"Secondary Button text"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.FS_ONLINE]
                  .secondaryButtonText
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.FS_ONLINE]
                  , "secondaryButtonText", $$v)},expression:"\n                localSettings[StylistsCertifications.FS_ONLINE]\n                  .secondaryButtonText\n              "}}),_c('v-text-field',{attrs:{"outlined":"","label":"Secondary Button link"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.FS_ONLINE]
                  .secondaryButtonLink
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.FS_ONLINE]
                  , "secondaryButtonLink", $$v)},expression:"\n                localSettings[StylistsCertifications.FS_ONLINE]\n                  .secondaryButtonLink\n              "}}),_c('v-switch',{staticClass:"mt-0",attrs:{"label":"Show Secondary Button"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.FS_ONLINE]
                  .showSecondaryButton
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.FS_ONLINE]
                  , "showSecondaryButton", $$v)},expression:"\n                localSettings[StylistsCertifications.FS_ONLINE]\n                  .showSecondaryButton\n              "}})],1)],1)],1),_c('v-col',{attrs:{"cols":"6","md":"4","lg":"2"}},[_c('v-card',[_c('v-card-title',[_vm._v("ML - REG")]),_c('v-card-text',[_c('v-text-field',{attrs:{"outlined":"","label":"Promo price"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.ML_REGULAR].promoPrice
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.ML_REGULAR], "promoPrice", _vm._n($$v))},expression:"\n                localSettings[StylistsCertifications.ML_REGULAR].promoPrice\n              "}}),_c('v-text-field',{attrs:{"outlined":"","label":"List price"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.ML_REGULAR].listPrice
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.ML_REGULAR], "listPrice", _vm._n($$v))},expression:"\n                localSettings[StylistsCertifications.ML_REGULAR].listPrice\n              "}}),_c('v-textarea',{attrs:{"outlined":"","label":"Paragraph"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.ML_REGULAR].paragraph
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.ML_REGULAR], "paragraph", $$v)},expression:"\n                localSettings[StylistsCertifications.ML_REGULAR].paragraph\n              "}}),_c('v-text-field',{attrs:{"outlined":"","label":"Primary Button text"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.ML_REGULAR].buttonText
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.ML_REGULAR], "buttonText", $$v)},expression:"\n                localSettings[StylistsCertifications.ML_REGULAR].buttonText\n              "}}),_c('v-text-field',{attrs:{"outlined":"","label":"Primary Button link"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.ML_REGULAR].buttonLink
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.ML_REGULAR], "buttonLink", $$v)},expression:"\n                localSettings[StylistsCertifications.ML_REGULAR].buttonLink\n              "}}),_c('v-text-field',{attrs:{"outlined":"","label":"Secondary Button text"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.ML_REGULAR]
                  .secondaryButtonText
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.ML_REGULAR]
                  , "secondaryButtonText", $$v)},expression:"\n                localSettings[StylistsCertifications.ML_REGULAR]\n                  .secondaryButtonText\n              "}}),_c('v-text-field',{attrs:{"outlined":"","label":"Secondary Button link"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.ML_REGULAR]
                  .secondaryButtonLink
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.ML_REGULAR]
                  , "secondaryButtonLink", $$v)},expression:"\n                localSettings[StylistsCertifications.ML_REGULAR]\n                  .secondaryButtonLink\n              "}}),_c('v-switch',{staticClass:"mt-0",attrs:{"label":"Show Secondary Button"},model:{value:(
                _vm.localSettings[_vm.StylistsCertifications.ML_REGULAR]
                  .showSecondaryButton
              ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.ML_REGULAR]
                  , "showSecondaryButton", $$v)},expression:"\n                localSettings[StylistsCertifications.ML_REGULAR]\n                  .showSecondaryButton\n              "}})],1)],1)],1),_c('v-col',{attrs:{"cols":"6","md":"4","lg":"2"}},[_c('v-card',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-card-title',[_vm._v("ML - CUT - PT1")]),_c('v-card-text',[_c('v-text-field',{attrs:{"outlined":"","label":"Promo price"},model:{value:(
                  _vm.localSettings[_vm.StylistsCertifications.ML_CUTTING].promoPrice1
                ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.ML_CUTTING], "promoPrice1", _vm._n($$v))},expression:"\n                  localSettings[StylistsCertifications.ML_CUTTING].promoPrice1\n                "}}),_c('v-text-field',{attrs:{"outlined":"","label":"List price"},model:{value:(
                  _vm.localSettings[_vm.StylistsCertifications.ML_CUTTING].listPrice1
                ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.ML_CUTTING], "listPrice1", _vm._n($$v))},expression:"\n                  localSettings[StylistsCertifications.ML_CUTTING].listPrice1\n                "}}),_c('v-textarea',{attrs:{"hide-details":"","outlined":"","label":"Paragraph"},model:{value:(
                  _vm.localSettings[_vm.StylistsCertifications.ML_CUTTING].paragraph1
                ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.ML_CUTTING], "paragraph1", $$v)},expression:"\n                  localSettings[StylistsCertifications.ML_CUTTING].paragraph1\n                "}})],1)],1),_c('v-divider'),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-card-title',[_vm._v("ML - CUT - PT2 ")]),_c('v-card-text',[_c('v-text-field',{attrs:{"outlined":"","label":"Promo price"},model:{value:(
                  _vm.localSettings[_vm.StylistsCertifications.ML_CUTTING].promoPrice2
                ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.ML_CUTTING], "promoPrice2", _vm._n($$v))},expression:"\n                  localSettings[StylistsCertifications.ML_CUTTING].promoPrice2\n                "}}),_c('v-text-field',{attrs:{"outlined":"","label":"List price"},model:{value:(
                  _vm.localSettings[_vm.StylistsCertifications.ML_CUTTING].listPrice2
                ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.ML_CUTTING], "listPrice2", _vm._n($$v))},expression:"\n                  localSettings[StylistsCertifications.ML_CUTTING].listPrice2\n                "}}),_c('v-textarea',{attrs:{"outlined":"","label":"Paragraph"},model:{value:(
                  _vm.localSettings[_vm.StylistsCertifications.ML_CUTTING].paragraph2
                ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.ML_CUTTING], "paragraph2", $$v)},expression:"\n                  localSettings[StylistsCertifications.ML_CUTTING].paragraph2\n                "}}),_c('v-text-field',{attrs:{"outlined":"","label":"Primary Button text"},model:{value:(
                  _vm.localSettings[_vm.StylistsCertifications.ML_CUTTING].buttonText
                ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.ML_CUTTING], "buttonText", $$v)},expression:"\n                  localSettings[StylistsCertifications.ML_CUTTING].buttonText\n                "}}),_c('v-text-field',{attrs:{"outlined":"","label":"Primary Button link"},model:{value:(
                  _vm.localSettings[_vm.StylistsCertifications.ML_CUTTING].buttonLink
                ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.ML_CUTTING], "buttonLink", $$v)},expression:"\n                  localSettings[StylistsCertifications.ML_CUTTING].buttonLink\n                "}}),_c('v-text-field',{attrs:{"outlined":"","label":"Secondary Button text"},model:{value:(
                  _vm.localSettings[_vm.StylistsCertifications.ML_CUTTING]
                    .secondaryButtonText
                ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.ML_CUTTING]
                    , "secondaryButtonText", $$v)},expression:"\n                  localSettings[StylistsCertifications.ML_CUTTING]\n                    .secondaryButtonText\n                "}}),_c('v-text-field',{attrs:{"outlined":"","label":"Secondary Button link"},model:{value:(
                  _vm.localSettings[_vm.StylistsCertifications.ML_CUTTING]
                    .secondaryButtonLink
                ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.ML_CUTTING]
                    , "secondaryButtonLink", $$v)},expression:"\n                  localSettings[StylistsCertifications.ML_CUTTING]\n                    .secondaryButtonLink\n                "}}),_c('v-switch',{staticClass:"mt-0",attrs:{"label":"Show Secondary Button"},model:{value:(
                  _vm.localSettings[_vm.StylistsCertifications.ML_CUTTING]
                    .showSecondaryButton
                ),callback:function ($$v) {_vm.$set(_vm.localSettings[_vm.StylistsCertifications.ML_CUTTING]
                    , "showSecondaryButton", $$v)},expression:"\n                  localSettings[StylistsCertifications.ML_CUTTING]\n                    .showSecondaryButton\n                "}})],1)],1)],1)],1)],1),_c('v-divider',{staticClass:"my-3"}),_c('v-card-actions',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"success","loading":_vm.isSending},on:{"click":_vm.handleSave}},[_vm._v("Save")])],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }