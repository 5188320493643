<template>
  <v-container class="pa-0" v-if="system">
    <LaserbeamerView
      :source="source"
      v-if="system === SystemOptions.LASERBEAMER"
    />
    <MicrolinesView
      :source="source"
      v-if="system === SystemOptions.MICROLINES"
    />
    <FreestylerView
      :source="source"
      v-if="system === SystemOptions.FREESTYLER"
    />
    <TapeView :source="source" v-if="system === SystemOptions.TAPE" />
  </v-container>
</template>

<script>
  import { SystemOptions, getFullSystemByUrl } from '@/config/enums'
  import { mapActions } from 'vuex'
  import FreestylerView from './FreestylerView'
  import LaserbeamerView from './LaserbeamerView'
  import MicrolinesView from './MicrolinesView'
  import sources from './source-stylists.js'
  import TapeView from './TapeView'

  export default {
    components: {
      LaserbeamerView,
      MicrolinesView,
      FreestylerView,
      TapeView
    },
    data() {
      return { SystemOptions, sources, getFullSystemByUrl }
    },
    computed: {
      system() {
        return (
          this.getFullSystemByUrl(this.$route.params.system) ||
          this.$store.getters.primarySystem
        )
      },
      source() {
        return this.sources[this.system]
      }
    },
    methods: {
      ...mapActions(['setPrimarySystem'])
    },
    created() {
      // 1. if no primary system in store and no system in url -> redirect to primary system page
      if (!this.$store.getters.primarySystem && !this.$route.params.system) {
        this.$router.push({
          name: 'stylist-primary-system',
          query: this.$route.query
        })
      }
      // 2. set primary system in store
      else if (this.$route.params.system) {
        this.setPrimarySystem({
          system: this.getFullSystemByUrl(this.$route.params.system)
        })
      }
    }
  }
</script>
