<template>
  <v-dialog
    v-model="showDialog"
    :max-width="maxWidth"
    @click:outside="handleClose"
    @keydown.esc="handleClose"
  >
    <v-card>
      <v-toolbar dark color="user_primary">
        <v-toolbar-title class="text-uppercase">My Account</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon @click="handleClose" style="cursor: pointer">mdi-close</v-icon>
      </v-toolbar>

      <v-card-text>
        <v-card-text class="text-center subtitle-1">{{
          user.email
        }}</v-card-text>

        <v-card-text class="d-flex justify-center pt-0" v-if="showAllMessages">
          <v-btn
            block
            small
            class="text-capitalize"
            rounded
            @click="handleAllMessages"
          >
            Messages
          </v-btn>
        </v-card-text>
        <v-card-text class="d-flex justify-center pt-0" v-if="showEditLink">
          <v-btn
            block
            small
            class="text-capitalize"
            rounded
            @click="handleEditAccountInfo"
          >
            Edit account information
          </v-btn>
        </v-card-text>
        <v-card-text
          class="d-flex justify-center pt-0"
          v-if="showSalonPageLink"
        >
          <v-btn
            block
            small
            class="text-capitalize"
            rounded
            @click="handleSalonPage"
          >
            My Page
          </v-btn>
        </v-card-text>
        <v-card-text class="d-flex justify-center py-0">
          <v-btn
            block
            small
            class="text-capitalize"
            rounded
            @click="handleChangePassword"
          >
            Change Password
          </v-btn>
        </v-card-text>
      </v-card-text>

      <v-card-actions class="d-flex justify-center pb-3">
        <v-btn
          small
          class="text-capitalize pa-3 white--text"
          rounded
          @click="handleLogout"
          color="red"
          >Log out</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    props: {
      user: {
        type: Object,
        require: true
      },
      maxWidth: {
        type: String,
        default: '330'
      },
      showDialog: {
        type: Boolean,
        required: false
      },
      title: {
        type: String,
        default: 'Response'
      }
    },
    computed: {
      showAllMessages() {
        return this.$route.name !== 'SalonMessages'
      },
      showEditLink() {
        return this.$route.name !== 'EditSalon'
      },
      showSalonPageLink() {
        return this.$route.name !== 'SalonPage'
      },
      salonId() {
        return this.$route.params.salonId || this.user.salon
      }
    },
    methods: {
      ...mapActions(['logout']),

      handleAllMessages() {
        this.$router.push({
          name: 'SalonMessages',
          params: { salonId: this.salonId },
          query: { from: this.$route.fullPath }
        })
        this.handleClose()
      },
      handleEditAccountInfo() {
        this.$router.push({
          name: 'EditSalon',
          params: { salonId: this.salonId },
          query: { from: this.$route.fullPath }
        })
        this.handleClose()
      },
      handleSalonPage() {
        this.$router.push({
          name: 'SalonPage',
          params: { salonId: this.salonId },
          query: { from: this.$route.fullPath }
        })
        this.handleClose()
      },
      handleChangePassword() {
        this.$router.push({
          name: 'ChangePasswordSalon',
          params: { salonId: this.salonId },
          query: { from: this.$route.fullPath }
        })
        this.handleClose()
      },
      handleLogout() {
        this.logout()
        this.$router.push({
          name: 'LoginSalon',
          query: { from: this.$route.query.from }
        })
        this.handleClose()
      },
      handleClose() {
        this.$emit('close')
      }
    }
  }
</script>
