import { StylistsAccountSource } from '@/config/enums'
import api from '@/services/api.service'
import { ContactMessageStylistTopics } from '../../config/enums'

// state
export const state = {
  list: [],
  messagesAndCalls: [],
  consumerMessages: [],
  c_h: [],
  st_h: [],
  st_h_contact_page: [],
  loading: false,
  error: '',
  c_s_Count: '',
  totalCount: 0,
  totalMessagesAndCallsCount: 0,
  totalConsumersMessagesCount: 0,
  c_s: null,
  allMessagesAndCallsCS: [],
  filteredMessagesAndCallsCS: [],
  totalFilteredMessagesAndCallsCS: 0,
  conversations: [],
  salonMessagesUnreadCount: null
}

// mutations
export const mutations = {
  salonMessagesUnreadCountFetched: (state, count) => {
    state.salonMessagesUnreadCount = count
  },
  conversationsFetched: (state, conversations) => {
    state.conversations = conversations
  },
  messagesFetched: (state, messages) => {
    state.list = messages
  },
  messagesAndCallsFetched: (state, mc) => {
    state.messagesAndCalls = mc
  },
  consumerMessagesFetched: (state, mc) => {
    state.consumerMessages = mc
  },
  totalCountFetched: (state, totalCount) => {
    state.totalCount = totalCount
  },
  totalMessagesAndCallsCountFetched: (state, totalCount) => {
    state.totalMessagesAndCallsCount = totalCount
  },
  totalConsumerMessagesCountFetched: (state, totalCount) => {
    state.totalConsumersMessagesCount = totalCount
  },
  filteredMessagesAndCallsCSFetched: (state, data) => {
    state.filteredMessagesAndCallsCS = data
  },
  totalFilteredMessagesAndCallsCSFetched: (
    state,
    totalFilteredMessagesAndCallsCS
  ) => {
    state.totalFilteredMessagesAndCallsCS = totalFilteredMessagesAndCallsCS
  },
  // allMessagesAndCallsCSFetched: (state, data) => {
  //   state.allMessagesAndCallsCS = data
  // },
  c_s_countFetched: (state, c_s_Count) => {
    state.c_s_Count = c_s_Count
  },
  c_s_fetched: (state, c_s) => {
    state.c_s = c_s
  },
  c_h_fetched: (state, c_h) => {
    state.c_h = c_h
  },
  st_h_fetched: (state, st_h) => {
    state.st_h = st_h
  },
  st_h_contact_page_fetched: (state, st_h_contact_page) => {
    state.st_h_contact_page = st_h_contact_page
  },
  c_h_edited: (state, editedMessage) => {
    state.c_h = state.c_h.map(message => {
      return message._id === editedMessage._id
        ? { ...editedMessage }
        : { ...message }
    })
  },
  st_h_contact_page_edited: (state, editedMessage) => {
    state.st_h_contact_page = state.st_h_contact_page.map(message => {
      return message._id === editedMessage._id
        ? { ...editedMessage }
        : { ...message }
    })
  },
  messageEdited: (state, editedMessage) => {
    state.list = state.list.map(message => {
      return message._id === editedMessage._id
        ? { ...editedMessage }
        : { ...message }
    })
  },
  messageRemoved: (state, id) =>
    (state.list = state.list.filter(s => s._id !== id)),
  loadingMessagesSet: state => (state.loading = true),
  loadingMessagesCleared: state => (state.loading = false),
  errorMessagesSet: (state, error) => (state.error = error),
  errorMessagesCleared: state => (state.error = '')
}

// helper:
const errorHandling = (commit, error) => {
  commit(
    'errorMessagesSet',
    error?.response?.data?.message || 'Something went wrong...'
  )
  commit('loadingMessagesCleared')
  setTimeout(() => {
    commit('errorMessagesCleared')
  }, 10000)
}

// actions
export const actions = {
  getMessagesCsv: async ({ commit }, payload) => {
    try {
      const response = await api.postMessagesCsv(payload)
      return response.data
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  getMessagesAndCallsCsv: async ({ commit }, payload) => {
    try {
      const response = await api.postMessagesAndCallsCsv(payload)
      return response.data
      // commit('loadingConsumersCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  getConsumerMessagesCsv: async ({ commit }, payload) => {
    try {
      const response = await api.postConsumerMessagesCsv(payload)
      return response.data
      // commit('loadingConsumersCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchConversations: async ({ commit }, salonId) => {
    // commit('loadingMessagesSet')
    try {
      const response = await api.getConversations(salonId)
      commit('conversationsFetched', response.data.data)
      commit('loadingMessagesCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchSalonMessagesUnreadCount: async ({ commit }, salonId) => {
    // commit('loadingMessagesSet')
    try {
      const response = await api.getSalonMessagesUnreadCount(salonId)
      commit('salonMessagesUnreadCountFetched', response.data.results)
      // commit('loadingMessagesCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchMessages: async ({ commit }) => {
    commit('loadingMessagesSet')
    try {
      const response = await api.getMessages()
      commit('messagesFetched', response.data.data)
      commit('loadingMessagesCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchMessagesAndCallsTableData: async ({ commit }, payload = {}) => {
    commit('loadingMessagesSet')

    const limit = payload.limit || 100000000
    const page = payload.page || 1
    const search = payload.search || {}
    const sortBy = payload.sortBy
    const sortDesc = payload.sortDesc

    try {
      const response = await api.getMessagesAndCallsTableData(
        limit,
        page,
        search,
        sortBy,
        sortDesc
      )

      commit('messagesAndCallsFetched', response.data.data)
      commit('totalMessagesAndCallsCountFetched', response.data.totalCount)
      commit('loadingMessagesCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchConsumerMessagesTableData: async ({ commit }, payload = {}) => {
    commit('loadingMessagesSet')

    const limit = payload.limit || 100000000
    const page = payload.page || 1
    const search = payload.search || {}
    const sortBy = payload.sortBy
    const sortDesc = payload.sortDesc

    try {
      const response = await api.getConsumerMessagesTableData(
        limit,
        page,
        search,
        sortBy,
        sortDesc
      )

      commit('consumerMessagesFetched', response.data.data)
      commit('totalConsumerMessagesCountFetched', response.data.totalCount)
      commit('loadingMessagesCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchMessagesTableData: async ({ commit }, payload = {}) => {
    commit('loadingMessagesSet')

    const limit = payload.limit || 100000000
    const page = payload.page || 1
    const search = payload.search || {}
    const sortBy = payload.sortBy
    const sortDesc = payload.sortDesc

    try {
      const response = await api.getMessagesTableData(
        limit,
        page,
        search,
        sortBy,
        sortDesc
      )

      commit('messagesFetched', response.data.data)
      commit('totalCountFetched', response.data.totalCount)
      commit('loadingMessagesCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchMessagesAndCallsCS: async ({ commit }, payload) => {
    commit('loadingMessagesSet')
    try {
      const response = await api.getMessagesAndCallsCS(payload)
      commit('filteredMessagesAndCallsCSFetched', response.data.data)
      commit('totalFilteredMessagesAndCallsCSFetched', response.data.results)
      // commit('allMessagesAndCallsCSFetched', response.data.allData)
      commit('loadingMessagesCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchCHMessages: async ({ commit }) => {
    commit('loadingMessagesSet')
    try {
      const response = await api.getCHMessages()
      commit('c_h_fetched', response.data.data)
      commit('loadingMessagesCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchSTHMessages: async ({ commit }) => {
    commit('loadingMessagesSet')
    try {
      const response = await api.getSTHMessages()

      commit(
        'st_h_contact_page_fetched',
        response.data.data.filter(
          message =>
            message?.stylist?.source === StylistsAccountSource.contact_page ||
            message.topic === ContactMessageStylistTopics.ULTRA ||
            message.topic === ContactMessageStylistTopics.DREAMWEFTS
        )
      )
      commit('loadingMessagesCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  send_c_s: async ({ commit }, data) => {
    // commit('loadingMessagesSet')
    try {
      await api.send_c_s(data)
      // commit('loadingMessagesCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  send_c_h: async ({ commit }, data) => {
    // commit('loadingMessagesSet')
    try {
      await api.send_c_h(data)
      // commit('loadingMessagesCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  send_s_h: async ({ commit }, data) => {
    // commit('loadingMessagesSet')
    try {
      await api.send_s_h(data)
      // commit('loadingMessagesCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  send_st_h: async ({ commit }, data) => {
    // commit('loadingMessagesSet')
    try {
      await api.send_st_h(data)
      // commit('loadingMessagesCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetch_c_s: async ({ commit }, payload) => {
    commit('loadingMessagesSet')
    const { salonId, consumerId } = payload
    try {
      const res = await api.get_c_s(salonId, consumerId)
      commit('c_s_fetched', res.data.data)
      commit('loadingMessagesCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetch_st_h: async ({ commit }, payload) => {
    commit('loadingMessagesSet')
    const { stylistId } = payload
    try {
      const res = await api.get_st_h(stylistId)
      commit('st_h_fetched', res.data.data)
      commit('loadingMessagesCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchCount_c_s: async ({ commit }, payload) => {
    const { salonId, consumerId } = payload
    try {
      const res = await api.getCount_c_s(salonId, consumerId)
      commit('c_s_countFetched', res.data.count)
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  // use it for deactivate also (send from component active:false)
  editMessage: async ({ commit }, message) => {
    // commit('loadingMessagesSet')

    try {
      const { data } = await api.patchMessage(message)
      // delete
      if (message.active === false) {
        commit('messageRemoved', message.id)
      }
      // edit
      else {
        commit('messageEdited', data.data)
      }
      // commit('loadingMessagesCleared')
    } catch (error) {
      commit(
        'errorMessagesSet',
        error?.response?.data?.message || 'Something went wrong...'
      )

      setTimeout(() => {
        commit('errorMessagesCleared')
      }, 10000)
    }
  },

  editCHMessage: async ({ commit }, message) => {
    commit('loadingMessagesSet')

    try {
      const { data } = await api.patchMessage(message)

      commit('c_h_edited', data.data)
      commit('loadingMessagesCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  editSTHMessage: async ({ commit }, message) => {
    commit('loadingMessagesSet')

    try {
      const { data } = await api.patchMessage(message)

      commit('st_h_contact_page_edited', data.data)
      commit('loadingMessagesCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  }
}
