<template>
  <v-container fluid>
    <v-data-table
      dense
      :headers="headers"
      :items="messages"
      item-key="id"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      class="elevation-1 ml-3"
      @page-count="pageCount = $event"
      :server-items-length="totalMessages"
      :loading="isLoading"
      @update:options="handleUpdateOptions"
      @dblclick:row="handleOpen"
    >
      <template v-slot:top>
        <v-snackbar
          centered
          top
          v-model="snackbar"
          :color="snackbarColor"
          :timeout="snackbarTimeout"
          >{{ notification }}</v-snackbar
        >
        <v-toolbar flat>
          <v-toolbar-title>Messages</v-toolbar-title>

          <v-divider class="mx-4" inset vertical></v-divider>
          <span class="title"
            >Count: {{ $store.state.messages.totalCount }}</span
          >
          <v-spacer></v-spacer>

          <!-- export -->
          <v-btn @click="handleExportData" :loading="isExportingData">
            <v-icon class="mx-1">mdi-download</v-icon>
            Export CSV
          </v-btn>
        </v-toolbar>
        <!-- search by date -->
        <DateFilter @change="handleDateChange" />
      </template>
      <!-- custom filters -->
      <template v-slot:body.prepend>
        <tr>
          <td v-for="header in headers" :key="header.value">
            <FilterField
              v-if="header.toFilter"
              :type="header.filterType"
              :options="header.filterOptions"
              @change="handleFilterChange($event, header)"
              :value="filters[header.value]"
            />
            <span v-else />
          </td>
        </tr>
      </template>

      <!-- system -->
      <template v-slot:item.system="{ item }">
        <v-chip x-small class="ma-2" color="pink" label text-color="white"
          ><v-icon x-small left> mdi-label </v-icon>{{ item.system }}</v-chip
        >
      </template>

      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | localeDate(true) }}
      </template>
    </v-data-table>
    <!-- NOTE: PAGINATION -->
    <div class="text-center pt-2" v-if="messages && messages.length > 0">
      <div class="text-center">
        <v-container>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination
                  v-model="page"
                  class="my-4"
                  :length="pageCount"
                  circle
                ></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </v-container>
</template>

<script>
  import DateFilter from '@/components/DateFilter.vue'
  import FilterField from '@/components/FilterField.vue'
  import { getMessagesTableInfo } from '@/config/tables.helper'
  import { saveAs } from 'file-saver'
  import { mapActions } from 'vuex'

  export default {
    data() {
      return {
        // pagintaion
        page: 1,
        itemsPerPage: 10,
        pageCount: 0,
        // sorting
        sortBy: 'createdAt',
        sortDesc: true,
        // search
        search: {},
        // other
        isExportingData: false,
        filters: getMessagesTableInfo(this).filters,
        headers: [],
        snackbar: false,
        snackbarTimeout: 2000,
        snackbarColor: 'blue',
        notification: '',
        isLoading: false
      }
    },
    computed: {
      messages() {
        return this.$store.state.messages.list
      },
      totalMessages() {
        return this.$store.state.messages.totalCount
      }
    },
    methods: {
      ...mapActions(['fetchMessagesTableData', 'getMessagesCsv']),
      handleOpen(event, row) {
        const { item } = row

        this.$router.push({
          name: 'AdminCommunication',
          params: {
            consumerId: item.consumerId,
            salonId: item.salonId
          }
        })
      },
      async handleUpdateOptions(options) {
        this.sortBy = options.sortBy[0] ? options.sortBy[0] : 'createdAt'
        this.sortDesc = options.sortDesc
        await this.updateData()
      },
      async updateData() {
        this.isLoading = true
        await this.fetchMessagesTableData({
          page: this.page,
          limit: this.itemsPerPage,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
          search: this.search
        })

        this.pageCount = Math.ceil(
          this.$store.state.messages.totalCount / this.itemsPerPage
        )
        this.isLoading = false
      },
      async handleDateChange(dates) {
        this.page = 1
        this.search['startDate'] = dates.startDate
        this.search['endDate'] = dates.endDate
        await this.updateData()
      },
      async handleFilterChange(value, header) {
        this.page = 1
        this.search[header.value] = value
        await this.updateData()
      },
      async handleExportData() {
        this.isExportingData = true
        let transform = {}
        getMessagesTableInfo(this).headers.forEach(h => {
          if (h.csv !== false) {
            transform[h.text] = h.value
          }
        })
        const data = await this.getMessagesCsv({ transform })
        const blob = new Blob([data], { type: 'text/csv' })
        saveAs(blob, 'messages.csv')
        this.isExportingData = false
      }
    },
    watch: {
      async page(newValue, oldValue) {
        await this.updateData()
      }
    },
    created() {
      this.headers = getMessagesTableInfo(this).headers
    },
    components: { FilterField, DateFilter }
  }
</script>
