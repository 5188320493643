<template>
  <v-dialog
    v-model="showDialog"
    :max-width="maxWidth"
    @click:outside="handleClose"
    @keydown.esc="handleClose"
  >
    <v-card>
      <v-toolbar dark color="user_primary">
        <v-spacer></v-spacer>
        <v-toolbar-title>My Account</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon @click="handleClose" style="cursor: pointer">mdi-close</v-icon>
      </v-toolbar>

      <v-card-text>
        <v-card-text class="d-flex justify-center pt-5 pb-0">
          <v-btn
            block
            class="text-capitalize"
            rounded
            @click="handleSeeApplicationsStatus"
          >
            Update my account
          </v-btn>
        </v-card-text>
      </v-card-text>

      <v-card-actions class="d-flex justify-center pb-5">
        <v-btn
          class="text-capitalize pa-3 white--text"
          rounded
          @click="handleLogout"
          color="red"
          >Log out</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    props: {
      user: {
        type: Object,
        require: true
      },
      maxWidth: {
        type: String,
        default: '330'
      },
      showDialog: {
        type: Boolean,
        required: false
      }
    },
    methods: {
      ...mapActions(['logout']),

      handleSeeApplicationsStatus() {
        if (!this.$store.state.auth.user.stylistId) return
        if (this.$route.name !== 'stylist-applications') {
          this.$router.push({
            name: 'stylist-applications',
            params: {
              stylistId: this.$store.getters.user.stylistId
            }
          })
        }

        this.handleClose()
      },

      handleLogout() {
        this.logout()
        this.$router.replace({
          name: 'stylist-login'
        })
        this.handleClose()
      },
      handleClose() {
        this.$emit('close')
      }
    }
  }
</script>
