import api from '@/services/api.service'

// state
export const state = {
  stylist: {},
  // licenseUrl: '',
  list: [],
  loading: false,
  error: '',
  totalCount: 0
}

// mutations
export const mutations = {
  stylistEdited: (state, editedStylist) => {
    state.list = state.list.map(stylist => {
      return stylist._id === editedStylist._id
        ? { ...editedStylist }
        : { ...stylist }
    })
  },
  stylistFetched: (state, stylist) => (state.stylist = stylist),
  // licenseAdded: (state, url) => (state.licenseUrl = url),
  loadingStylistsAccountSet: state => (state.loading = true),
  loadingStylistsAccountCleared: state => (state.loading = false),
  errorStylistsAccountSet: (state, error) => (state.error = error),
  errorStylistsAccountCleared: state => (state.error = '')
}

// helper:
const errorHandling = (commit, error) => {
  commit(
    'errorStylistsAccountSet',
    error?.response?.data?.message || 'Something went wrong...'
  )
  commit('loadingStylistsAccountCleared')
  setTimeout(() => {
    commit('errorStylistsAccountCleared')
  }, 10000)
}

// actions
export const actions = {
  fetchStylistAccount: async ({ commit }, id) => {
    commit('loadingStylistsAccountSet')
    try {
      const response = await api.getStylistAccount(id)
      commit('stylistFetched', response.data.data)
      commit('loadingStylistsAccountCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  editStylistAccount: async ({ commit }, stylist) => {
    commit('loadingStylistsAccountSet')

    try {
      const { data } = await api.patchStylistAccount(stylist)

      commit('stylistEdited', data.data)
      commit('loadingStylistsAccountCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  }
  // addLicense: async ({ commit }, payload) => {
  //   const { id, form } = payload
  //   // commit('loadingSalonsSet')
  //   try {
  //     const res = await api.uploadLicense(id, form)
  //     commit('licenseAdded', res.data.data.cosmetologyLicenseFile)
  //     // commit('loadingSalonsCleared')
  //   } catch (error) {
  //     commit(
  //       'errorSalonsSet',
  //       error?.response?.data?.message || 'Something went wrong...'
  //     )
  //     setTimeout(() => {
  //       commit('errorSalonsCleared')
  //     }, 10000)
  //     // commit('loadingSalonsCleared', false)
  //   }
  // }
}
