// stylists
import CertificationsWrapper from './views/user/WF1/apple-style/certifications/CertificationsWrapper.vue'
import AppleStylePage from './views/user/WF1/apple-style/stylists/StylistsAppleStyleWrapper.vue'
import StylistAccountPage from './views/user/WF1/stylists/AccountPage.vue'
import StylistAdditionalInfo from './views/user/WF1/stylists/AdditionalInfo.vue'
import Ads1 from './views/user/WF1/stylists/Ads1.vue'
import ApplicationPage from './views/user/WF1/stylists/ApplicationPage'
import ApplicationPrimarySystem from './views/user/WF1/stylists/ApplicationPrimarySystem'
import StylistChangePassword from './views/user/WF1/stylists/ChangePassword'
import Extensions1 from './views/user/WF1/stylists/Extensions1.vue'
import Extensions2 from './views/user/WF1/stylists/Extensions2.vue'
import Extensions2_1 from './views/user/WF1/stylists/Extensions2_1.vue'
import Extensions2_2 from './views/user/WF1/stylists/Extensions2_2.vue'
import Extensions3 from './views/user/WF1/stylists/Extensions3.vue'
import FinalStatusPage from './views/user/WF1/stylists/FinalStatusPage.vue'
import StylistForgotPassword from './views/user/WF1/stylists/ForgotPassword'
import License from './views/user/WF1/stylists/License.vue'
import StylistLogin from './views/user/WF1/stylists/Login'
import OtherStylists from './views/user/WF1/stylists/OtherStylists.vue'
import OtherStylistsWithTypeUrl from './views/user/WF1/stylists/OtherStylistsWithTypeUrl.vue'
import PrimarySystem from './views/user/WF1/stylists/PrimarySystem.vue'
import Restoration1 from './views/user/WF1/stylists/Restoration1.vue'
import Restoration1_1 from './views/user/WF1/stylists/Restoration1_1.vue'
import Restoration2 from './views/user/WF1/stylists/Restoration2.vue'
import Restoration2_1 from './views/user/WF1/stylists/Restoration2_1.vue'
import Restoration3 from './views/user/WF1/stylists/Restoration3.vue'
import Restoration4 from './views/user/WF1/stylists/Restoration4.vue'
import StylistSignup from './views/user/WF1/stylists/Signup'
import StepsPage from './views/user/WF1/stylists/StepsPage.vue'
import StylistSystemInfo from './views/user/WF1/stylists/StylistSystemInfo.vue'
import StylistSystemPrices from './views/user/WF1/stylists/StylistSystemPrices.vue'
import System1 from './views/user/WF1/stylists/System1.vue'
import TapesAdditionalStep from './views/user/WF1/stylists/TapesAdditionalStep.vue'
import StylistValidateForgotPasswordCode from './views/user/WF1/stylists/ValidateForgotPasswordCode'
import StylistsWrapper from './views/user/WF1/stylists/Wrapper.vue'

const account = {
  path: 'stylists/account',
  component: StylistAccountPage,
  children: [
    {
      path: 'primary-system',
      name: 'stylist-primary-system',
      component: PrimarySystem
    },
    {
      path: 'bento-boxes/:system',
      name: 'stylist-apple-style',
      component: AppleStylePage
    },
    {
      path: 'additional-step',
      name: 'stylist-tapes-additional-step',
      component: TapesAdditionalStep
    },
    {
      path: 'steps',
      name: 'stylist-steps',
      component: StepsPage
    },
    {
      path: 'system-info',
      name: 'stylist-system-info',
      component: StylistSystemInfo
    },
    {
      path: 'system-prices',
      name: 'stylist-system-prices',
      component: StylistSystemPrices
    },
    {
      path: 'register',
      name: 'stylist-signup',
      component: StylistSignup
    },
    {
      path: 'login',
      name: 'stylist-login',
      component: StylistLogin
    },
    {
      path: 'forgot-password',
      name: 'stylist-forgot-password',
      component: StylistForgotPassword
    },
    {
      path: 'validate-code',
      name: 'stylist-validate-forgot-password-code',
      component: StylistValidateForgotPasswordCode
    },
    {
      path: 'change-password',
      name: 'stylist-change-password',
      component: StylistChangePassword
    }
  ]
}

const application = {
  path: 'stylists/application/:stylistId',
  name: 'stylist-applications',
  component: ApplicationPage,
  meta: { requiresStylistAuth: true }
}

const certifications = {
  path: 'stylists/certification/:certification/:stylistId',
  name: 'stylist-certification',
  component: CertificationsWrapper
  // meta: { requiresStylistAuth: true }
}

const other = {
  path: 'stylists/contact',
  name: 'stylist-other-contact',
  component: OtherStylists
}

const otherWithUrlType = {
  path: 'stylists/contact/:type',
  name: 'stylist-other-contact-with-url',
  component: OtherStylistsWithTypeUrl
}

const form = {
  path: 'stylists/form',
  component: StylistsWrapper,
  name: 'stylist-wrapper',
  children: [
    {
      path: ':stylistId/:applicationId/primary-system',
      name: 'stylist-application-primary-system',
      component: ApplicationPrimarySystem,
      meta: { requiresStylistAuth: true }
    },
    {
      path: ':stylistId/:applicationId/info',
      name: 'stylist-additional-info',
      component: StylistAdditionalInfo,
      meta: { requiresStylistAuth: true }
    },
    {
      path: ':stylistId/:applicationId/license',
      name: 'stylist-license',
      component: License,
      meta: { requiresStylistAuth: true }
    },
    {
      path: ':stylistId/:applicationId/system1',
      name: 'stylist-system1',
      component: System1,
      meta: { requiresStylistAuth: true }
    },
    {
      path: ':stylistId/:applicationId/extensions1',
      name: 'stylist-extensions1',
      component: Extensions1,
      meta: { requiresStylistAuth: true }
    },
    {
      path: ':stylistId/:applicationId/extensions2',
      name: 'stylist-extensions2',
      component: Extensions2,
      meta: { requiresStylistAuth: true }
    },
    {
      path: ':stylistId/:applicationId/extensions2_1',
      name: 'stylist-extensions2_1',
      component: Extensions2_1,
      meta: { requiresStylistAuth: true }
    },
    {
      path: ':stylistId/:applicationId/extensions2_2',
      name: 'stylist-extensions2_2',
      component: Extensions2_2,
      meta: { requiresStylistAuth: true }
    },
    {
      path: ':stylistId/:applicationId/extensions3',
      name: 'stylist-extensions3',
      component: Extensions3,
      meta: { requiresStylistAuth: true }
    },
    {
      path: ':stylistId/:applicationId/restoration1',
      name: 'stylist-restoration1',
      component: Restoration1,
      meta: { requiresStylistAuth: true }
    },
    {
      path: ':stylistId/:applicationId/restoration1_1',
      name: 'stylist-restoration1_1',
      component: Restoration1_1,
      meta: { requiresStylistAuth: true }
    },
    {
      path: ':stylistId/:applicationId/restoration2',
      name: 'stylist-restoration2',
      component: Restoration2,
      meta: { requiresStylistAuth: true }
    },
    {
      path: ':stylistId/:applicationId/restoration2_1',
      name: 'stylist-restoration2_1',
      component: Restoration2_1,
      meta: { requiresStylistAuth: true }
    },
    {
      path: ':stylistId/:applicationId/restoration3',
      name: 'stylist-restoration3',
      component: Restoration3,
      meta: { requiresStylistAuth: true }
    },
    {
      path: ':stylistId/:applicationId/restoration4',
      name: 'stylist-restoration4',
      component: Restoration4,
      meta: { requiresStylistAuth: true }
    },
    {
      path: ':stylistId/:applicationId/ads1',
      name: 'stylist-ads1',
      component: Ads1,
      meta: { requiresStylistAuth: true }
    },
    {
      path: ':stylistId/:applicationId/final',
      name: 'stylist-final-status',
      component: FinalStatusPage,
      meta: { requiresStylistAuth: true }
    }
  ]
}

export { account, application, certifications, form, other, otherWithUrlType }
