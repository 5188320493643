<template>
  <v-container fluid class="elevation-1 pa-5">
    <div
      class="d-flex justify-center"
      style="min-height: 1000px"
      v-if="isLoading"
    >
      <v-progress-circular indeterminate :size="70" :width="7" color="purple" />
    </div>
    <template v-else>
      <v-snackbar
        centered
        top
        v-model="snackbar"
        :color="snackbarColor"
        :timeout="snackbarTimeout"
        >{{ notification }}</v-snackbar
      >
      <v-row>
        <!-- LB FULL HEAD  -->
        <v-col cols="6" md="4" lg="2"
          ><v-card
            ><v-card-title>LB - FULL HEAD</v-card-title>

            <!-- promo price -->
            <v-card-text
              ><v-text-field
                v-model.number="
                  localSettings[StylistsCertifications.LB_FULL].promoPrice
                "
                outlined
                label="Promo price"
              />
              <!-- list price -->
              <v-text-field
                v-model.number="
                  localSettings[StylistsCertifications.LB_FULL].listPrice
                "
                outlined
                label="List price"
              />

              <!-- paragraph -->
              <v-textarea
                v-model="
                  localSettings[StylistsCertifications.LB_FULL].paragraph
                "
                outlined
                label="Paragraph"
              />

              <!-- button text -->
              <v-text-field
                v-model="
                  localSettings[StylistsCertifications.LB_FULL].buttonText
                "
                outlined
                label="Primary Button text"
              />

              <!-- button link -->
              <v-text-field
                v-model="
                  localSettings[StylistsCertifications.LB_FULL].buttonLink
                "
                outlined
                label="Primary Button link"
              />

              <!-- secondary button text -->
              <v-text-field
                v-model="
                  localSettings[StylistsCertifications.LB_FULL]
                    .secondaryButtonText
                "
                outlined
                label="Secondary Button text"
              />

              <!-- secondary button link -->
              <v-text-field
                v-model="
                  localSettings[StylistsCertifications.LB_FULL]
                    .secondaryButtonLink
                "
                outlined
                label="Secondary Button link"
              />

              <!-- show secondary button -->
              <v-switch
                class="mt-0"
                v-model="
                  localSettings[StylistsCertifications.LB_FULL]
                    .showSecondaryButton
                "
                label="Show Secondary Button"
              />
            </v-card-text>
          </v-card>
        </v-col>

        <!-- LB HALF -->
        <v-col cols="6" md="4" lg="2"
          ><v-card
            ><v-card-title>LB - HALF HEAD</v-card-title>
            <!-- promo price -->
            <v-card-text
              ><v-text-field
                v-model.number="
                  localSettings[StylistsCertifications.LB_HALF].promoPrice
                "
                outlined
                label="Promo price"
              />
              <!-- list price -->
              <v-text-field
                v-model.number="
                  localSettings[StylistsCertifications.LB_HALF].listPrice
                "
                outlined
                label="List price"
              />

              <!-- paragraph -->
              <v-textarea
                v-model="
                  localSettings[StylistsCertifications.LB_HALF].paragraph
                "
                outlined
                label="Paragraph"
              />

              <!-- button text -->
              <v-text-field
                v-model="
                  localSettings[StylistsCertifications.LB_HALF].buttonText
                "
                outlined
                label="Primary Button text"
              />

              <!-- button link -->
              <v-text-field
                v-model="
                  localSettings[StylistsCertifications.LB_HALF].buttonLink
                "
                outlined
                label="Primary Button link"
              />

              <!-- secondary button text -->
              <v-text-field
                v-model="
                  localSettings[StylistsCertifications.LB_HALF]
                    .secondaryButtonText
                "
                outlined
                label="Secondary Button text"
              />

              <!-- secondary button link -->
              <v-text-field
                v-model="
                  localSettings[StylistsCertifications.LB_HALF]
                    .secondaryButtonLink
                "
                outlined
                label="Secondary Button link"
              />

              <!-- show secondary button -->
              <v-switch
                class="mt-0"
                v-model="
                  localSettings[StylistsCertifications.LB_HALF]
                    .showSecondaryButton
                "
                label="Show Secondary Button"
              /> </v-card-text></v-card
        ></v-col>

        <!-- FS IN PERSON  -->
        <v-col cols="6" md="4" lg="2"
          ><v-card
            ><v-card-title>FS - IN PERSON</v-card-title>
            <!-- promo price -->
            <v-card-text
              ><v-text-field
                v-model.number="
                  localSettings[StylistsCertifications.FS_IN_PERSON].promoPrice
                "
                outlined
                label="Promo price"
              />
              <!-- list price -->
              <v-text-field
                v-model.number="
                  localSettings[StylistsCertifications.FS_IN_PERSON].listPrice
                "
                outlined
                label="List price"
              />

              <!-- paragraph -->
              <v-textarea
                v-model="
                  localSettings[StylistsCertifications.FS_IN_PERSON].paragraph
                "
                outlined
                label="Paragraph"
              />

              <!-- button text -->
              <v-text-field
                v-model="
                  localSettings[StylistsCertifications.FS_IN_PERSON].buttonText
                "
                outlined
                label="Primary Button text"
              />

              <!-- button link -->
              <v-text-field
                v-model="
                  localSettings[StylistsCertifications.FS_IN_PERSON].buttonLink
                "
                outlined
                label="Primary Button link"
              />

              <!-- secondary button text -->
              <v-text-field
                v-model="
                  localSettings[StylistsCertifications.FS_IN_PERSON]
                    .secondaryButtonText
                "
                outlined
                label="Secondary Button text"
              />

              <!-- secondary button link -->
              <v-text-field
                v-model="
                  localSettings[StylistsCertifications.FS_IN_PERSON]
                    .secondaryButtonLink
                "
                outlined
                label="Secondary Button link"
              />

              <!-- show secondary button -->
              <v-switch
                class="mt-0"
                v-model="
                  localSettings[StylistsCertifications.FS_IN_PERSON]
                    .showSecondaryButton
                "
                label="Show Secondary Button"
              />
            </v-card-text> </v-card
        ></v-col>

        <!-- FS ONLINE -->
        <v-col cols="6" md="4" lg="2"
          ><v-card
            ><v-card-title>FS - ONLINE</v-card-title>
            <!-- promo price -->
            <v-card-text
              ><v-text-field
                v-model.number="
                  localSettings[StylistsCertifications.FS_ONLINE].promoPrice
                "
                outlined
                label="Promo price"
              />
              <!-- list price -->
              <v-text-field
                v-model.number="
                  localSettings[StylistsCertifications.FS_ONLINE].listPrice
                "
                outlined
                label="List price"
              />

              <!-- paragraph -->
              <v-textarea
                v-model="
                  localSettings[StylistsCertifications.FS_ONLINE].paragraph
                "
                outlined
                label="Paragraph"
              />

              <!-- button text -->
              <v-text-field
                v-model="
                  localSettings[StylistsCertifications.FS_ONLINE].buttonText
                "
                outlined
                label="Primary Button text"
              />

              <!-- button link -->
              <v-text-field
                v-model="
                  localSettings[StylistsCertifications.FS_ONLINE].buttonLink
                "
                outlined
                label="Primary Button link"
              />

              <!-- secondary button text -->
              <v-text-field
                v-model="
                  localSettings[StylistsCertifications.FS_ONLINE]
                    .secondaryButtonText
                "
                outlined
                label="Secondary Button text"
              />

              <!-- secondary button link -->
              <v-text-field
                v-model="
                  localSettings[StylistsCertifications.FS_ONLINE]
                    .secondaryButtonLink
                "
                outlined
                label="Secondary Button link"
              />

              <!-- show secondary button -->
              <v-switch
                class="mt-0"
                v-model="
                  localSettings[StylistsCertifications.FS_ONLINE]
                    .showSecondaryButton
                "
                label="Show Secondary Button"
              />
            </v-card-text> </v-card
        ></v-col>

        <!-- ML REGULAR -->
        <v-col cols="6" md="4" lg="2"
          ><v-card
            ><v-card-title>ML - REG</v-card-title>
            <!-- promo price -->
            <v-card-text
              ><v-text-field
                v-model.number="
                  localSettings[StylistsCertifications.ML_REGULAR].promoPrice
                "
                outlined
                label="Promo price"
              />
              <!-- list price -->
              <v-text-field
                v-model.number="
                  localSettings[StylistsCertifications.ML_REGULAR].listPrice
                "
                outlined
                label="List price"
              />

              <!-- paragraph -->
              <v-textarea
                v-model="
                  localSettings[StylistsCertifications.ML_REGULAR].paragraph
                "
                outlined
                label="Paragraph"
              />

              <!-- button text -->
              <v-text-field
                v-model="
                  localSettings[StylistsCertifications.ML_REGULAR].buttonText
                "
                outlined
                label="Primary Button text"
              />

              <!-- button link -->
              <v-text-field
                v-model="
                  localSettings[StylistsCertifications.ML_REGULAR].buttonLink
                "
                outlined
                label="Primary Button link"
              />

              <!-- secondary button text -->
              <v-text-field
                v-model="
                  localSettings[StylistsCertifications.ML_REGULAR]
                    .secondaryButtonText
                "
                outlined
                label="Secondary Button text"
              />

              <!-- secondary button link -->
              <v-text-field
                v-model="
                  localSettings[StylistsCertifications.ML_REGULAR]
                    .secondaryButtonLink
                "
                outlined
                label="Secondary Button link"
              />

              <!-- show secondary button -->
              <v-switch
                class="mt-0"
                v-model="
                  localSettings[StylistsCertifications.ML_REGULAR]
                    .showSecondaryButton
                "
                label="Show Secondary Button"
              />
            </v-card-text> </v-card
        ></v-col>

        <!-- ML CUTTING -->
        <v-col cols="6" md="4" lg="2">
          <v-card>
            <!-- ML CUTTING PART 1 -->
            <v-col cols="12" class="pt-0"
              ><v-card-title>ML - CUT - PT1</v-card-title>
              <!-- promo price -->
              <v-card-text
                ><v-text-field
                  v-model.number="
                    localSettings[StylistsCertifications.ML_CUTTING].promoPrice1
                  "
                  outlined
                  label="Promo price"
                />
                <!-- list price -->
                <v-text-field
                  v-model.number="
                    localSettings[StylistsCertifications.ML_CUTTING].listPrice1
                  "
                  outlined
                  label="List price"
                />

                <!-- paragraph -->
                <v-textarea
                  hide-details
                  v-model="
                    localSettings[StylistsCertifications.ML_CUTTING].paragraph1
                  "
                  outlined
                  label="Paragraph"
                />
              </v-card-text>
            </v-col>

            <v-divider></v-divider>
            <!-- ML CUTTING PART 2 -->
            <v-col cols="12" class="pt-0"
              ><v-card-title>ML - CUT - PT2 </v-card-title>
              <!-- promo price -->
              <v-card-text
                ><v-text-field
                  v-model.number="
                    localSettings[StylistsCertifications.ML_CUTTING].promoPrice2
                  "
                  outlined
                  label="Promo price"
                />
                <!-- list price -->
                <v-text-field
                  v-model.number="
                    localSettings[StylistsCertifications.ML_CUTTING].listPrice2
                  "
                  outlined
                  label="List price"
                />

                <!-- paragraph -->
                <v-textarea
                  v-model="
                    localSettings[StylistsCertifications.ML_CUTTING].paragraph2
                  "
                  outlined
                  label="Paragraph"
                />

                <!-- button text -->
                <v-text-field
                  v-model="
                    localSettings[StylistsCertifications.ML_CUTTING].buttonText
                  "
                  outlined
                  label="Primary Button text"
                />

                <!-- button link -->
                <v-text-field
                  v-model="
                    localSettings[StylistsCertifications.ML_CUTTING].buttonLink
                  "
                  outlined
                  label="Primary Button link"
                />
                <!-- secondary button text -->
                <v-text-field
                  v-model="
                    localSettings[StylistsCertifications.ML_CUTTING]
                      .secondaryButtonText
                  "
                  outlined
                  label="Secondary Button text"
                />

                <!-- secondary button link -->
                <v-text-field
                  v-model="
                    localSettings[StylistsCertifications.ML_CUTTING]
                      .secondaryButtonLink
                  "
                  outlined
                  label="Secondary Button link"
                />

                <!-- show secondary button -->
                <v-switch
                  class="mt-0"
                  v-model="
                    localSettings[StylistsCertifications.ML_CUTTING]
                      .showSecondaryButton
                  "
                  label="Show Secondary Button"
                />
              </v-card-text> </v-col
          ></v-card>
        </v-col>
      </v-row>
      <v-divider class="my-3"></v-divider>
      <v-card-actions class="d-flex justify-end">
        <v-btn color="success" @click="handleSave" :loading="isSending"
          >Save</v-btn
        >
      </v-card-actions>
    </template>
  </v-container>
</template>

<script>
  import { StylistsCertifications } from '@/config/enums'
  import { mapActions } from 'vuex'

  const defaultSetup = {
    promoPrice: null,
    listPrice: null,
    paragraph: '',
    buttonText: '',
    buttonLink: ''
  }

  const defaultSetupMlCutting = {
    promoPrice1: null,
    listPrice1: null,
    paragraph1: '',
    promoPrice2: null,
    listPrice2: null,
    paragraph2: '',
    buttonText: '',
    buttonLink: ''
  }

  export default {
    data() {
      return {
        StylistsCertifications,
        localSettings: {},
        isLoading: true,
        isSending: false,
        snackbar: false,
        snackbarTimeout: 2000,
        snackbarColor: 'blue',
        notification: ''
      }
    },
    computed: {
      routeName() {
        return this.$route.name
      },
      isCanada() {
        return this.$route.name === 'stylist-certifications-canada'
      },
      certificate() {
        if (this.isCanada) {
          return this.$store.getters.certificateSettingsCanada
        }
        return this.$store.getters.certificateSettings
      }
    },
    methods: {
      ...mapActions(['fetchSettings', 'editSettings']),
      async updateData() {
        this.isLoading = true
        await this.fetchSettings()
        this.localSettings[StylistsCertifications.LB_HALF] =
          this.certificate?.settings?.[StylistsCertifications.LB_HALF] ||
          defaultSetup

        this.localSettings[StylistsCertifications.LB_FULL] =
          this.certificate?.settings?.[StylistsCertifications.LB_FULL] ||
          defaultSetup

        this.localSettings[StylistsCertifications.FS_IN_PERSON] =
          this.certificate?.settings?.[StylistsCertifications.FS_IN_PERSON] ||
          defaultSetup

        this.localSettings[StylistsCertifications.FS_ONLINE] =
          this.certificate?.settings?.[StylistsCertifications.FS_ONLINE] ||
          defaultSetup

        this.localSettings[StylistsCertifications.ML_REGULAR] =
          this.certificate?.settings?.[StylistsCertifications.ML_REGULAR] ||
          defaultSetup

        this.localSettings[StylistsCertifications.ML_CUTTING] =
          this.certificate?.settings?.[StylistsCertifications.ML_CUTTING] ||
          defaultSetupMlCutting
        this.isLoading = false
      },
      async handleSave() {
        this.isSending = true
        await this.editSettings({
          id: this.certificate?._id,
          settings: this.localSettings
        })
        this.isSending = false
        if (!this.$store.state.settings.error) {
          this.notification = 'Certifications info saved.'
          this.snackbarColor = 'green'
          this.snackbar = true
        }
      }
    },
    watch: {
      async routeName() {
        await this.updateData()
      }
    },

    async created() {
      await this.updateData()
    }
  }
</script>
