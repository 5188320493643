<template>
  <v-card class="elevation-12 mx-auto pa-3 pb-7" max-width="450">
    <!-- if microlines: screen with images -->
    <template v-if="answer === SystemOptions.MICROLINES">
      <v-card-title class="pb-0 breakword d-flex text-h3" style="color: #222222"
        >{{ getInfo().title }}
      </v-card-title>
      <v-card-text
        class="pt-0 text-h5 breakword d-flex justify-start"
        style="color: #222222"
        >{{ getInfo().subtitle }}</v-card-text
      >
      <v-card-text
        class="breakword d-flex justify-center"
        v-if="getInfo().subtitleCaption"
        >{{ getInfo().subtitleCaption }}</v-card-text
      >
      <v-card-text class="px-0 pt-0">
        <v-img
          contain
          src="@/assets/wf1-microlines/2.jpg"
          lazy-src="@/assets/wf1-microlines/2.jpg"
        >
        </v-img>
      </v-card-text>
      <v-card-text class="px-0 pt-0">
        <v-img
          contain
          src="@/assets/wf1-microlines/3.jpg"
          lazy-src="@/assets/wf1-microlines/3.jpg"
        >
        </v-img>
      </v-card-text>
      <v-card-text class="px-0 pt-0">
        <v-img
          contain
          src="@/assets/wf1-microlines/4.jpg"
          lazy-src="@/assets/wf1-microlines/4.jpg"
        >
        </v-img>
      </v-card-text>
      <v-card-text class="px-0 pt-0">
        <v-img
          contain
          src="@/assets/wf1-microlines/5.jpg"
          lazy-src="@/assets/wf1-microlines/5.jpg"
        >
        </v-img>
      </v-card-text>
      <v-card-text class="px-0 pt-0">
        <v-img
          contain
          src="@/assets/wf1-microlines/6.jpg"
          lazy-src="@/assets/wf1-microlines/6.jpg"
        >
        </v-img>
      </v-card-text>
      <v-card-text class="px-0 pt-0">
        <v-img
          contain
          src="@/assets/wf1-microlines/7.jpg"
          lazy-src="@/assets/wf1-microlines/7.jpg"
        >
        </v-img>
      </v-card-text>
      <v-card-text class="px-0 pt-0" style="cursor: pointer">
        <v-img
          @click="handleShowMicrolinesVideoDialog"
          contain
          src="@/assets/wf1-microlines/8.jpg"
          lazy-src="@/assets/wf1-microlines/8.jpg"
        >
        </v-img>
      </v-card-text>
      <v-dialog
        v-model="showMicrolinesVideoDialog"
        max-width="800"
        @click:outside="stopVideo"
        @keydown.esc="() => stopVideo"
      >
        <v-card>
          <v-row>
            <v-spacer></v-spacer>
            <v-col class="d-flex justify-end pt-4 pr-4">
              <v-icon @click="stopVideo" style="cursor: pointer"
                >mdi-close</v-icon
              ></v-col
            >
          </v-row>
          <v-card-text class="pa-1">
            <iframe
              id="microlines-video"
              ref="video"
              :src="microlinesVideoSrc"
              width="100%"
              height="350"
              title=" "
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture; encrypted-media"
              allowfullscreen
            ></iframe>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-card-text
        class="title d-flex justify-center pb-0 pt-2"
        style="color: #222222"
        >Ready to be</v-card-text
      >
      <v-card-text
        style="color: #222222"
        class="pt-0 mb-4 text-h4 d-flex justify-center"
        >your best?</v-card-text
      >
      <v-card-text class="text-center pt-0" v-if="getInfo().caption">{{
        getInfo().caption
      }}</v-card-text>
      <v-row
        ><v-col class="d-flex justify-center pt-0"
          ><v-btn
            :width="detectMob() ? '90%' : '82%'"
            :color="!!this.answer ? 'user_cta' : ''"
            :dark="!!this.answer"
            rounded
            depressed
            @click="handleSelect"
            class="text-none"
            >{{ getInfo().btnText }}</v-btn
          ></v-col
        ></v-row
      >
    </template>
    <!-- other systems: screen with video -->
    <template v-else>
      <v-card-title class="breakword d-flex justify-center"
        >{{ getInfo().title }}
      </v-card-title>
      <v-card-text class="breakword d-flex justify-center">{{
        getInfo().subtitle
      }}</v-card-text>
      <v-card-text
        class="breakword d-flex justify-center"
        v-if="getInfo().subtitleCaption"
        >{{ getInfo().subtitleCaption }}</v-card-text
      >

      <v-row>
        <v-col class="px-0">
          <iframe
            ref="video"
            :src="getInfo().video"
            width="100%"
            height="400"
            title=" "
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-pict
          ure"
            allowfullscreen
          ></iframe
        ></v-col>
      </v-row>
      <v-card-text class="text-center pt-0" v-if="getInfo().caption">{{
        getInfo().caption
      }}</v-card-text>
      <v-row
        ><v-col class="d-flex justify-center pt-0"
          ><v-btn
            :width="detectMob() ? '90%' : '82%'"
            :color="!!this.answer ? 'user_cta' : ''"
            :dark="!!this.answer"
            rounded
            depressed
            @click="handleSelect"
            class="text-none"
            >{{ getInfo().btnText }}</v-btn
          ></v-col
        ></v-row
      >
    </template>
  </v-card>
</template>

<script>
  import { SystemOptions } from '@/config/enums'
  import { detectMob } from '@/utils/helpers'

  export default {
    data() {
      return {
        showMicrolinesVideoDialog: false,
        microlinesVideoSrc:
          'https://player.vimeo.com/video/732572685?h=14e4e2940a',
        detectMob,
        SystemOptions,
        answer: ''
      }
    },

    methods: {
      handleShowMicrolinesVideoDialog() {
        this.showMicrolinesVideoDialog = true
      },
      stopVideo() {
        this.showMicrolinesVideoDialog = false
        this.$refs.video.src = ''
        this.$refs.video.src = this.microlinesVideoSrc
      },
      handleSelect() {
        // tape
        if (this.getInfo().link) {
          window.location.href = this.getInfo().link
        } else {
          const query = { system: this.answer }
          if (this.$route.query['source'])
            query.source = this.$route.query['source']

          this.$router.push({
            name: 'stylist-system-prices',
            query: { ...this.$route.query, system: this.answer }
          })
        }
      },
      getInfo() {
        let title, subtitle, subtitleCaption, video, caption, btnText, link

        switch (this.answer) {
          case SystemOptions.FREESTYLER:
            title = 'Hairdreams® FreeStyler'
            subtitle = 'QUALITY IN EVERY BOND'
            video = 'https://player.vimeo.com/video/732572600?h=998429f657'
            btnText = 'Get started with Hairdreams'
            link = ''
            break
          case SystemOptions.LASERBEAMER:
            title = 'Hairdreams® Laserbeamer Nano'
            subtitle = 'QUALITY MEETS TECHNOLOGY'
            video = 'https://player.vimeo.com/video/732572798?h=97bae7e2da'
            btnText = 'Get started with Hairdreams'
            link = ''
            break

          case SystemOptions.MICROLINES:
            // title = 'Hairdreams® MicroLines'
            title = 'MicroLines'
            // subtitle = 'QUALITY MEETS TECHNOLOGY'
            subtitle = 'Hair Loss System'
            video = 'https://player.vimeo.com/video/732572685?h=14e4e2940a'
            btnText = 'Get started with Hairdreams'
            link = ''
            break

          case SystemOptions.TAPE:
            title = 'Hairdreams® Tape-Ins'
            subtitle = 'QUALITY MEETS TECHNOLOGY'
            video = 'https://player.vimeo.com/video/732572878?h=94bfd2f8c5'
            btnText = 'Shop now'
            caption = 'Salon pros can purchase Tape-Ins online'
            link = 'https://hairdreamstapes.com/'
            break
        }

        return {
          title,
          subtitle,
          subtitleCaption,
          video,
          btnText,
          caption,
          link
        }
      }
    },
    created() {
      if (!this.$store.getters.primarySystem) {
        this.$router.push({
          name: 'stylist-primary-system',
          query: this.$route.query
        })
      }
      this.answer = this.$store.getters.primarySystem
    }
  }
</script>

<style lang="scss" scoped>
  .breakword {
    word-break: break-word;
  }
</style>
