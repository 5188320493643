<template>
  <div class="chat">
    <v-snackbar
      centered
      top
      v-model="snackbar"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      >{{ notification }}</v-snackbar
    >
    <v-container class="fill-height">
      <v-row class="fill-height pb-14" align="end">
        <!-- note: is moderator -->
        <v-col class="mx-0 mx-sm-5" v-if="isModerator">
          <div
            v-for="(item, index) in chat"
            :key="index"
            :class="[
              'd-flex flex-column justify-center my-2 ',
              item.from == MessageFrom.HD
                ? 'align-end ml-5'
                : 'align-start mr-5'
            ]"
          >
            <span class="mx-2 caption">{{
              item.from == MessageFrom.HD
                ? 'Hairdreams'
                : item.from == MessageFrom.S
                ? getTitleCase(host)
                : getTitleCase(guestName)
            }}</span>

            <!-- image -->
            <div v-if="item.imageUrl">
              <img
                :src="item.imageUrl"
                class="chat-image"
                alt="Sent image"
                @click="openImagePopup(item.imageUrl)"
              />
            </div>

            <span
              v-if="item.from == MessageFrom.HD && item.msg"
              class="bubble blue--bubble px-5 text-caption text-sm-body-1"
              >{{ item.msg }}</span
            >

            <span
              v-if="item.from != MessageFrom.HD && item.msg"
              class="bubble px-5 text-caption text-sm-body-1"
              :class="
                item.from == MessageFrom.S ? 'grey--bubble' : 'orange--bubble'
              "
              >{{ item.msg }}</span
            >
            <span v-if="item.createdAt" class="caption px-2">{{
              item.createdAt | localeDate(true)
            }}</span>
          </div>
        </v-col>
        <!-- not moderator -->
        <v-col v-else>
          <div
            v-for="(item, index) in chat"
            :key="index"
            :class="[
              'd-flex flex-column justify-center my-2 ',
              item.from == host
                ? 'align-end ml-5'
                : item.from === MessageFrom.HD
                ? 'align-center mx-5'
                : 'align-start mr-5'
            ]"
          >
            <span class="mx-2 mx-sm-5 caption">{{
              item.from == host
                ? getTitleCase(hostName)
                : item.from == MessageFrom.HD
                ? 'Hairdreams'
                : getTitleCase(guestName)
            }}</span>
            <!-- image -->
            <div v-if="item.imageUrl">
              <img
                :src="item.imageUrl"
                class="chat-image"
                alt="Sent image"
                @click="openImagePopup(item.imageUrl)"
              />
            </div>
            <span
              v-if="item.from == host && item.msg"
              class="bubble blue--bubble px-5 mr-1 mr-sm-5 text-caption text-sm-body-1"
              >{{ item.msg }}</span
            >

            <!-- <v-avatar :color="item.from == host ? 'indigo' : 'red'" size="36">
              <span class="white--text">{{
                item.from == host
                  ? hostName[0]
                  : item.from == MessageFrom.HD
                  ? 'HD'
                  : guestName[0]
              }}</span>
            </v-avatar> -->
            <span
              v-if="item.from != host && item.msg"
              class="bubble ml-2 ml-sm-5 px-5 text-caption text-sm-body-1"
              :class="
                item.from == MessageFrom.HD ? 'orange--bubble' : 'grey--bubble'
              "
              >{{ item.msg }}</span
            >
            <span v-if="item.createdAt" class="caption px-5">{{
              item.createdAt | localeDate(true)
            }}</span>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <!-- Image Popup -->
    <v-dialog v-model="imageDialog" max-width="600">
      <v-card>
        <v-img :src="selectedImage" class="popup-image"></v-img>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="closeImagePopup">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Footer -->
    <v-footer class="chat-footer" v-if="showSend">
      <v-container class="ma-0 pa-0">
        <v-row no-gutters>
          <v-col>
            <vue-scroll>
              <div class="d-flex flex-row align-center">
                <v-textarea
                  auto-grow
                  rows="1"
                  class="text-caption text-sm-body-1"
                  ref="input"
                  @blur="$emit('blurFocus')"
                  v-model="msg"
                  placeholder="Type Message"
                ></v-textarea>

                <!-- Input for file selection (hidden) -->
                <input
                  type="file"
                  @change="handleFileChange"
                  ref="fileInput"
                  style="display: none"
                  accept="image/*"
                />

                <!-- Show image preview before sending -->
                <div
                  v-if="selectedImage"
                  :style="{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '0px'
                  }"
                >
                  <img
                    :src="imagePreviewUrl"
                    alt="Image preview"
                    :style="{
                      maxWidth: '100px',
                      borderRadius: '5px',
                      marginRight: '5px'
                    }"
                  />
                  <v-btn icon @click="removeImage">
                    <v-icon small>mdi-close</v-icon>
                  </v-btn>
                </div>

                <!-- Camera icon to trigger file input -->
                <v-btn icon class="ml-4" @click="triggerFileInput">
                  <v-icon small>mdi-camera</v-icon>
                </v-btn>

                <!-- Send button -->
                <v-btn
                  icon
                  class="ml-4"
                  @click="handleSend"
                  :disabled="isDisabledSend && !selectedImage"
                  :loading="isSending"
                >
                  <v-icon small>mdi-send</v-icon>
                </v-btn>
              </div>
            </vue-scroll>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
  import { MessageFrom, MessageTypes } from '@/config/enums'
  import { getTitleCase } from '@/utils/helpers'
  import { mapActions } from 'vuex'

  const showMessage = (minutes = 15) => {
    let show = false
    const messageTime = localStorage.getItem('message-time')
    if (!messageTime) {
      show = true
    } else {
      const messageTimeMS = new Date(messageTime).getTime()
      const timeNowMS = new Date().getTime()
      const timePassedMS = timeNowMS - messageTimeMS
      show = timePassedMS > minutes * 60 * 1000
    }
    localStorage.setItem('message-time', new Date())
    return show
  }

  export default {
    props: {
      isModerator: {
        type: Boolean,
        default: false
      },
      showSend: {
        type: Boolean,
        default: true
      },
      messages: {
        type: Array,
        required: true
      },
      host: { type: String, required: true },
      hostName: { type: String, required: true },
      guestName: { type: String, required: true },
      focus: { type: Boolean, default: false }
    },
    data() {
      return {
        getTitleCase,
        isSending: false,
        MessageFrom,
        chat: [],
        msg: null,
        selectedImage: null,
        imagePreviewUrl: null,
        snackbar: false,
        snackbarTimeout: 2000,
        snackbarColor: 'red',
        notification: '',
        imageDialog: false
      }
    },
    computed: {
      isDisabledSend() {
        return this.isSending || !this.msg || !this.msg.trim()
      }
    },
    methods: {
      ...mapActions(['send_c_s']),
      openImagePopup(imageUrl) {
        this.selectedImage = imageUrl
        this.imageDialog = true
      },
      closeImagePopup() {
        this.selectedImage = null
        this.imageDialog = false
      },
      triggerFileInput() {
        this.$refs.fileInput.click()
      },
      handleFileChange(event) {
        const file = event.target.files[0]
        const maxSize = 10 * 1024 * 1024
        if (!file) return
        if (file.size > maxSize) {
          this.snackbarColor = 'red'
          this.notification = `File size should be less than 10MB.`
          this.snackbar = true
          return
        }
        this.selectedImage = file
        this.imagePreviewUrl = URL.createObjectURL(file)
      },
      removeImage() {
        this.selectedImage = null
        this.imagePreviewUrl = null
      },
      handleSend() {
        // handle empty message and image
        if (!this.selectedImage && !this.msg) {
          this.snackbarColor = 'red'
          this.notification = `Please enter a message or select an image.`
          this.snackbar = true
          return
        }

        // populate form data for sending to backend
        const formData = new FormData()
        formData.append('type', MessageTypes.C_S)
        formData.append(
          'from',
          this.isModerator ? this.MessageFrom.HD : this.host
        )
        formData.append('body', this.msg || '')
        formData.append('salon', this.$route.params.salonId)
        formData.append('consumer', this.$route.params.consumerId)
        if (this.selectedImage) formData.append('file', this.selectedImage)
        if (this.host === MessageFrom.S) formData.append('salonRead', true)
        if (this.host === MessageFrom.C) formData.append('salonArchived', false)

        // add message to chat
        this.chat.push({
          from: this.isModerator ? this.MessageFrom.HD : this.host,
          msg: this.msg ? this.msg.trim() : '',
          imageUrl: this.imagePreviewUrl,
          createdAt: new Date()
        })

        this.msg = null

        // send message to backend
        this.send_c_s(formData)

        this.selectedImage = null
        this.imagePreviewUrl = null
        this.addReply()
      },
      addReply() {
        if (this.isModerator) return
        // note: check for 1 min (also on backend)
        if (!showMessage(1)) return

        this.chat.push({
          from: this.MessageFrom.HD,
          msg: `Thank you. We'll alert you when ${
            this.host === MessageFrom.S
              ? this.guestName.split(' ')[0]
              : this.getTitleCase(this.guestName)
          } replies.`
        })
      }
    },
    created() {
      this.chat = this.messages
        .map(m => {
          return {
            msg: m.body,
            imageUrl: m.imageUrl,
            from: m.from,
            createdAt: m.createdAt
          }
        })
        .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      // // add first message for wf3
      // if (
      //   this.host === MessageFrom.S &&
      //   !this.isModerator &&
      //   this.messages.filter(m => m.from === MessageFrom.S).length < 1
      // ) {
      //   this.chat.unshift({
      //     msg: 'Reminder: End Goal = Consultation! 😉',
      //     from: MessageFrom.HD
      //   })
      // }

      // add first message for wf6 if only one message
      if (
        this.host === MessageFrom.C &&
        !this.isModerator &&
        this.messages.length === 1
      ) {
        this.chat.push({
          msg: `Your message has been sent to ${this.guestName}. We will notify you when the salon replies, which is usually within 24h.`,
          from: MessageFrom.HD
        })
      }
    },
    watch: {
      focus() {
        if (this.focus) {
          this.$refs.input.focus()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .box {
    transition: 1.5s all cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  .chat-footer {
    margin-top: 40px;

    .image-preview-container {
      display: flex;
      align-items: center;
      margin-right: 0px;
    }
    .preview-image {
      max-width: 100px;
      border-radius: 5px;
      margin-right: 5px;
    }
  }
  .bubble {
    border-radius: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    max-width: 90%;
  }
  .blue--bubble {
    background-color: #1982fc;
    color: white;
  }
  .grey--bubble {
    background-color: lightgrey;
    color: black;
  }
  .orange--bubble {
    background-color: orange;
    color: white;
  }
  .chat-image {
    max-width: 200px;
    border-radius: 10px;
    margin-top: 5px;
  }

  .popup-image {
    width: 100%;
    height: auto;
  }
</style>
