<template>
  <v-container class="pt-0" v-if="!$store.state.messages.loading">
    <v-card class="elevation-12 mx-auto" max-width="650">
      <v-toolbar dark color="user_primary">
        <v-toolbar-title class="text-sm-h5 mx-auto"
          >Contact Hairdreams</v-toolbar-title
        >
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-text-field
                auto-resize
                hide-details
                label="First name"
                v-model="message.firstName"
              >
              </v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                hide-details
                label="Last name"
                v-model="message.lastName"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <VuePhoneNumberInput
                show-code-on-list
                @update="handleSmsPhoneUpdate"
                v-model="message.smsPhone.phoneNumber"
                no-example
                default-country-code="US"
                :only-countries="['US', 'CA']"
                color="#222"
                valid-color="#222"
                error-color="red"
                height="100"
                :size="$vuetify.breakpoint.smAndDown ? 'sm' : 'lg'"
                :no-flags="$vuetify.breakpoint.smAndDown ? true : false"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="9">
              <v-text-field
                hide-details
                label="Email"
                v-model="message.email"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                hide-details
                label="Zip"
                v-model="message.zip"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pt-0">
              <v-select
                class="text-truncate"
                v-model="message.topic"
                :items="selectItems"
                item-text="text"
                item-value="value"
                label="Topic"
              >
              </v-select>
            </v-col>
          </v-row>
          <template v-if="message.topic">
            <v-row
              v-if="message.topic === ContactMessageStylistTopics.ONE_SYSTEM"
            >
              <v-card-text class="pt-0 pl-3 text-subtitle-1" style="color: red"
                >Great! Click
                <a
                  @click="
                    () =>
                      $router.push({
                        name: 'stylist-primary-system',
                        query: {
                          ...$route.query,
                          source: 'salonfinder_prointake_contact_page_'
                        }
                      })
                  "
                  >here</a
                >
                to start.</v-card-text
              ></v-row
            >
            <v-row
              v-if="
                message.topic ===
                ContactMessageStylistTopics.MORE_THAN_ONE_SYSTEM
              "
            >
              <v-card-text class="pt-0 pl-3 text-subtitle-1" style="color: red">
                Great! Here is an easy way to get info or apply for multiple
                systems:
                <ul>
                  <li>Pick your favorite system</li>
                  <li>Select "Extensions and Hair loss" when asked</li>
                  <li>
                    Great! Click
                    <a
                      @click="
                        () =>
                          $router.push({
                            name: 'stylist-primary-system',
                            query: {
                              ...$route.query,
                              source: 'salonfinder_prointake_contact_page_'
                            }
                          })
                      "
                      >here</a
                    >
                    to start.
                  </li>
                </ul>
              </v-card-text>
            </v-row>

            <v-row
              v-if="
                message.topic === ContactMessageStylistTopics.HAIRDREAMS_PARTNER
              "
            >
              <v-card-text class="pt-0 pl-3 text-subtitle-1" style="color: red">
                Call <a href="tel:888-434-2471">888-434-2471</a> to speak to
                your sales representative or email
                <a href="mailto:hairdreams@bht-usa.com"
                  >hairdreams@bht-usa.com</a
                >.
              </v-card-text>
            </v-row>

            <template
              v-if="message.topic === ContactMessageStylistTopics.OTHER"
            >
              <v-row>
                <v-col cols="12" sm="12">
                  <v-textarea
                    filled
                    outlined
                    prepend-inner-icon="mdi-comment"
                    hide-details
                    auto-grow
                    name="input-7-1"
                    label="Message"
                    placeholder="Write us a message"
                    v-model="message.body"
                  ></v-textarea>
                </v-col>
              </v-row>
              <!-- <v-row class="pb-0">
                <v-col class="pb-0">
                  <v-checkbox
                    class="pb-0"
                    v-model="message.sendPromotion"
                    label="I want to receive promotional emails"
                /></v-col>
              </v-row> -->
              <v-row class="justify-center py-0 align-start">
                <v-col cols="12" class="py-0 pb-0 d-flex align-start">
                  <v-checkbox
                    class="checkbox pt-0 mt-0"
                    v-model="message.notice"
                    :ripple="false"
                  />

                  <v-col class="pa-0" style="line-height: 14px">
                    <v-card-text class="pa-0 pb-1 checkbox body-1 grey-color"
                      >I understand the
                      <a
                        href="https://iwanthairdreams.com/pages/notice-of-collection-of-personal-information"
                        target="_blank"
                      >
                        Notice of Collection of Personal Information</a
                      >
                    </v-card-text>
                  </v-col>
                </v-col>
              </v-row>
              <v-card-actions class="pr-0 pb-0">
                <v-spacer></v-spacer>
                <!-- <v-btn rounded @click="onClose"> Close </v-btn> -->
                <v-btn
                  class="white--text"
                  rounded
                  color="user_primary"
                  @click="handleSend"
                  :disabled="!isValid"
                  :loading="isSending"
                  >Send
                </v-btn>
              </v-card-actions>
            </template>
          </template>
        </v-container>
      </v-card-text>
    </v-card>

    <SimpleModal
      :showDialog="responseDialog"
      :message="responseMessage"
      @closeSimpleModal="closeResponseDialog"
    />
  </v-container>
</template>

<script>
  import {
    ContactMessageSources,
    ContactMessageStylistTopics,
    MessageFrom,
    MessageTypes,
    ThankYouPageSources
  } from '@/config/enums'
  import { mapActions } from 'vuex'

  export default {
    data() {
      return {
        ContactMessageStylistTopics,
        isSending: false,
        responseMessage: '',
        responseDialog: false,
        message: {
          type: MessageTypes.ST_H,
          from: MessageFrom.ST,
          source: '',
          topic: '',
          firstName: '',
          lastName: '',
          smsPhone: { phoneNumber: '', formattedNumber: '', validated: false },
          email: '',
          stylist: '',
          body: '',
          zip: '',
          sendPromotion: false
        }
      }
    },
    computed: {
      selectItems() {
        return Object.values(ContactMessageStylistTopics).filter(
          topic =>
            topic !== ContactMessageStylistTopics.DREAMWEFTS &&
            topic !== ContactMessageStylistTopics.ULTRA
        )
      },
      isValid() {
        return (
          !!this.message.firstName &&
          !!this.message.lastName &&
          !!this.message.smsPhone.phoneNumber &&
          !!this.message.email &&
          !!this.message.type &&
          !!this.message.body &&
          !!this.message.topic &&
          !!this.message.zip &&
          !!this.message.notice
        )
      }
    },
    methods: {
      ...mapActions(['send_st_h']),
      handleSmsPhoneUpdate(obj) {
        this.message.smsPhone.formattedNumber = obj.formattedNumber
      },
      closeResponseDialog() {
        this.responseDialog = false
        this.onClose()
      },

      async handleSend() {
        if (this.isValid) {
          this.isSending = true
          // don't send empty consumer
          if (!this.message.consumer) {
            delete this.message.consumer
          }
          await this.send_st_h(this.message)
          if (!this.$store.state.messages.error) {
            this.responseMessage = 'Message has been sent!'
            this.responseDialog = true
            this.dialog = false
            this.$router.replace({
              name: 'ThankYouPage',
              params: { source: ThankYouPageSources.contact },
              query: this.$route.query
            })
          }
          this.isSending = false
        }
      }
    },
    async created() {
      // review: sources and topics
      this.message.source = this.$route.query.source || ''
      if (this.message.source === ContactMessageSources.no_salon_found) {
        this.message.topic = ContactMessageStylistTopics.NO_SALON
      }
      if (this.message.source === ContactMessageSources.miles_100) {
        this.message.topic = ContactMessageStylistTopics.HD_DIDNT_CONTACT_ME
      }
    }
  }
</script>
