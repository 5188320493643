<template>
  <v-container>
    <v-card class="elevation-12 mx-auto" max-width="450">
      <!-- is loading -->
      <v-card-text v-if="isLoading" class="text-center">
        <v-progress-circular
          indeterminate
          color="blue-grey"
        ></v-progress-circular>
      </v-card-text>
      <!-- finished loading -->
      <template v-else>
        <v-card-title class="justify-center">Choose action</v-card-title>
        <v-card-text
          v-for="app in finishedApplications"
          :key="app._id"
          @click="handleViewApplication(app._id)"
          class="d-flex justify-center"
          ><v-btn>View My Application {{ app.createdAt | localeDate }}</v-btn>
        </v-card-text>
        <v-card-text
          v-if="showContinue"
          @click="handleContinue"
          class="d-flex justify-center"
          ><v-btn>Continue</v-btn>
        </v-card-text>
        <v-card-text v-if="showStartOver" class="d-flex justify-center"
          ><v-btn @click="handleStartOver">Start Over</v-btn></v-card-text
        >
        <v-card-text v-if="showStartOver" class="text-center font-weight-bold">
          WARNING! This deletes ALL prior entries/answers.</v-card-text
        >

        <v-card-text v-if="showApplyAgain" class="d-flex justify-center"
          ><v-btn @click="handleApplyAgain">Apply Again</v-btn></v-card-text
        >
      </template>
    </v-card>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    data() {
      return {
        isLoading: true
      }
    },
    computed: {
      stylist() {
        return this.$store.state.applications.applications[0].account
      },
      applicationsCount() {
        return this.$store.state.applications.applications.length
      },
      finishedApplications() {
        return this.$store.getters.finishedApplications
      },
      hasFinishedApplication() {
        return this.$store.getters.hasFinishedApplication
      },
      lastFinishedApplication() {
        return this.$store.getters.lastFinishedApplication
      },
      isPassedMoreThan90DaysSinceLastFinishedApplication() {
        const ninetyDaysInMillis = 90 * 24 * 60 * 60 * 1000 // 90 days in milliseconds
        const lastFinishedTime = new Date(
          this.lastFinishedApplication.createdAt
        ).getTime()
        const currentTime = new Date().getTime()

        return lastFinishedTime < currentTime - ninetyDaysInMillis
      },
      hasPendingApplication() {
        return this.$store.getters.hasPendingApplication
      },
      // has pending applications
      showContinue() {
        return this.hasPendingApplication
      },
      // has pending applications
      showStartOver() {
        return this.hasPendingApplication
      },
      // no pending applications and has finished application && (passed 90 days or forceApplyAgain flag is true)
      showApplyAgain() {
        return (
          !this.hasPendingApplication &&
          this.hasFinishedApplication &&
          (this.isPassedMoreThan90DaysSinceLastFinishedApplication ||
            this.stylist.forceApplyAgain)
        )
      }
    },
    methods: {
      ...mapActions([
        'addApplication',
        'resetApplication',
        'fetchApplicationsByStylist',
        'setFinishedApplication',
        'setContinueApplication'
      ]),
      handleViewApplication(id) {
        this.setFinishedApplication(id)
        this.$router.push({
          name: 'stylist-final-status',
          params: {
            stylistId: this.$route.params.stylistId,
            applicationId: id
          },
          query: this.$route.query
        })
      },
      handleContinue() {
        this.setContinueApplication()
        this.$router.push({
          name: this.$store.state.applications.application.currentScreen,
          params: {
            stylistId: this.$route.params.stylistId,
            applicationId: this.$store.state.applications.application._id
          },
          query: this.$route.query
        })
      },
      handleApplyAgain() {
        this.createApplication()
      },
      async createApplication() {
        await this.addApplication({
          stylistId: this.$route.params.stylistId,
          primarySystem: this.$store.getters.primarySystem || ''
        })
        this.$router.push({
          name: 'stylist-additional-info',
          params: {
            stylistId: this.$route.params.stylistId,
            applicationId: this.$store.state.applications.application._id
          },
          query: this.$route.query
        })
      },
      async handleStartOver() {
        await this.resetApplication(
          this.$store.state.applications.application._id
        )
        this.$router.push({
          name: 'stylist-additional-info',
          params: {
            stylistId: this.$route.params.stylistId,
            applicationId: this.$store.state.applications.application._id
          },
          query: this.$route.query
        })
      }
    },
    async created() {
      await this.fetchApplicationsByStylist(this.$route.params.stylistId)

      // if no applications, create one
      if (this.applicationsCount === 0) {
        await this.createApplication()
      }
      // if (this.hasFinishedApplication) {
      //   this.$router.push({
      //     name: 'stylist-final-status',
      //     params: {
      //       stylistId: this.$route.params.stylistId,
      //       applicationId: this.$store.state.applications.applications?.find(
      //         app => app.finished
      //       )._id
      //     },
      //     query: this.$route.query
      //   })
      // }
      this.isLoading = false
    }
  }
</script>
