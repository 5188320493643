import api from '../../services/api.service'

// state
export const state = {
  application: null,
  applications: [],
  applicationsCount: 0,
  currentScreen: null,
  screenHistory: [],
  nextScreen: '',
  isValid: false,
  loading: false,
  error: '',
  list: [],
  totalCount: 0,
  primarySystem: null
}

// getters
export const getters = {
  finishedApplications: state => {
    return state.applications.filter(app => app.finished)
  },
  hasFinishedApplication: state => {
    return !!state.applications.find(app => app.finished)
  },
  lastFinishedApplication: state => {
    // sort finished applications by createdAt and return the last one
    return state.applications
      .filter(app => app.finished)
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0]
  },
  hasPendingApplication: state => {
    return !!state.applications.find(app => !app.finished)
  },
  primarySystem: state => {
    return state.primarySystem
  },
  statusML: state => {
    return state.application.statusML
  },
  statusLB: state => {
    return state.application.statusLB
  },
  statusFS: state => {
    return state.application.statusFS
  },
  statusTA: state => {
    return state.application.statusTA
  },
  // force flags
  forceML_Reg: state => {
    return state.application.forceML_Reg
  },
  forceML_Cut: state => {
    return state.application.forceML_Cut
  },
  forceML: state => {
    return state.application.forceML_Cut || state.application.forceML_Reg
  },
  forceLB_Full_Head: state => {
    return state.application.forceLB_Full_Head
  },
  forceLB_Half_Head: state => {
    return state.application.forceLB_Half_Head
  },
  forceLB: state => {
    return (
      state.application.forceLB_Full_Head || state.application.forceLB_Half_Head
    )
  },
  forceFS_InSalon: state => {
    return state.application.forceFS_InSalon
  },
  forceFS_Online: state => {
    return state.application.forceFS_Online
  },
  forceFS: state => {
    return state.application.forceFS_InSalon || state.application.forceFS_Online
  },
  forceTA: state => {
    return state.application.forceTA
  },
  distanceApproved: state => {
    return state.application.distanceApproved || ''
  },
  licenseApproved: state => {
    return state.application.licenseApproved || ''
  }
}

// mutations
export const mutations = {
  primarySystemSet: (state, system) => (state.primarySystem = system),
  applicationsFetched: (state, applications) => {
    state.list = applications
  },
  totalCountFetched: (state, totalCount) => {
    state.totalCount = totalCount
  },
  applicationAdded: (state, application) => {
    state.application = application
  },
  applicationsByStylistFetched: (state, { applications, count }) => {
    state.applications = applications
    state.applicationsCount = count
    state.application = applications.find(app => !app.finished)
  },
  // form
  applicationSet: (state, application) => {
    state.application = application
  },
  nextScreenSet: (state, screen) => {
    state.nextScreen = screen
  },
  currentScreenSet: (state, screen) => {
    state.currentScreen = screen
  },
  screenHistorySet: (state, screenHistory) => {
    state.screenHistory = screenHistory
  },
  isValidSet: (state, isValid) => {
    state.isValid = isValid
  },
  // api
  applicationFetched: (state, application) => (state.application = application),
  applicationEdited: (state, application) => (state.application = application),
  // basic flow
  loadingApplicationsSet: state => (state.loading = true),
  loadingApplicationsCleared: state => (state.loading = false),
  errorApplicationsSet: (state, error) => (state.error = error),
  errorApplicationsCleared: state => (state.error = '')
}

// helper:
const errorHandling = (commit, error) => {
  commit(
    'errorApplicationsSet',
    error?.response?.data?.message || 'Something went wrong...'
  )
  commit('loadingApplicationsCleared')
  setTimeout(() => {
    commit('errorApplicationsCleared')
  }, 10000)
}

// helper:
const editApplication = async (commit, application) => {
  // commit('loadingApplicationsSet')
  try {
    const response = await api.patchApplication(application)
    commit('applicationEdited', response.data.data)
    // commit('loadingApplicationsCleared')
  } catch (error) {
    console.log(error)
    // errorHandling(commit, error)
  }
}

// actions
export const actions = {
  setPrimarySystem: async ({ commit }, { system }) => {
    commit('primarySystemSet', system)
  },
  setContinueApplication: ({ state, commit }) => {
    const application = state.applications.find(app => !app.finished)
    commit('applicationSet', application)
  },
  setFinishedApplication: ({ state, commit }, id) => {
    const application = state.applications.find(app => app._id === id)
    commit('applicationSet', application)
  },
  setNextScreen: ({ commit }, screen) => {
    commit('nextScreenSet', screen)
  },
  setIsValid: ({ commit }, isValid) => {
    commit('isValidSet', isValid)
  },
  // api
  setApplicationData: async ({ state, commit }, { field, answer }) => {
    commit('applicationSet', { ...state.application, [field]: answer })
    await editApplication(commit, {
      _id: state.application._id,
      [field]: answer
    })
  },
  setCurrentScreen: ({ state, commit }, currentScreen) => {
    commit('currentScreenSet', currentScreen)
    editApplication(commit, {
      _id: state.application._id,
      currentScreen
    })
  },
  setScreenHistory: ({ state, commit }, screenHistory) => {
    commit('screenHistorySet', screenHistory)
    editApplication(commit, {
      _id: state.application._id,
      screenHistory
    })
  },
  fetchApplicationsTableData: async ({ commit }, payload = {}) => {
    commit('loadingApplicationsSet')

    const limit = payload.limit || 100000000
    const page = payload.page || 1
    const search = payload.search || {}
    const sortBy = payload.sortBy
    const sortDesc = payload.sortDesc
    const status = payload.status

    try {
      const response = await api.getApplicationsTableData(
        limit,
        page,
        search,
        sortBy,
        sortDesc,
        status
      )

      commit('applicationsFetched', response.data.data)
      commit('totalCountFetched', response.data.totalCount)
      commit('loadingApplicationsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },

  fetchApplication: async ({ commit }, id) => {
    commit('loadingApplicationsSet')
    try {
      const response = await api.getApplication(id)
      commit('applicationFetched', response.data.data)
      commit('currentScreenSet', response.data.data.currentScreen)
      commit('screenHistorySet', response.data.data.screenHistory)
      commit('loadingApplicationsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  addApplication: async ({ commit }, applicationData) => {
    commit('loadingApplicationsSet')
    try {
      const response = await api.postApplication(applicationData)
      commit('applicationAdded', response.data.data)
      commit('loadingApplicationsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  resetApplication: async ({ commit }, id) => {
    commit('loadingApplicationsSet')
    try {
      const response = await api.resetApplication(id)
      commit('applicationAdded', response.data.data)
      commit('loadingApplicationsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  editApplication: async ({ commit }, applicationData) => {
    commit('loadingApplicationsSet')
    try {
      const response = await api.patchApplication(applicationData)
      commit('applicationAdded', response.data.data)
      commit('loadingApplicationsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  calculateStatuses: async ({ commit }, { id, sendEmail = false }) => {
    commit('loadingApplicationsSet')
    try {
      const response = await api.calculateStatuses(id, sendEmail)
      commit('applicationAdded', response.data.data)
      commit('loadingApplicationsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchApplicationsByStylist: async ({ commit }, stylistId) => {
    commit('loadingApplicationsSet')
    try {
      const response = await api.getApplicationsByAccount(stylistId)
      commit('applicationsByStylistFetched', {
        applications: response.data.data.applications,
        count: response.data.data.count
      })
      commit('loadingApplicationsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  addLicense: async ({ commit }, payload) => {
    const { id, form } = payload
    try {
      const res = await api.uploadLicense(id, form)
      commit('applicationSet', {
        ...state.application,
        cosmetologyLicenseFile: res.data.data.cosmetologyLicenseFile
      })
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  addAdminLicense: async ({ commit }, payload) => {
    const { id, form } = payload
    try {
      const res = await api.uploadAdminLicense(id, form)
      // commit('adminLicenseAdded', res.data.data.adminLicense)
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  getApplicationsCsv: async ({ commit }, payload) => {
    const { data, status } = payload
    try {
      // in payload are transform, ids for export and toUpdate flag
      const response = await api.postApplicationsCsv(data, status)
      return response.data
      // commit('loadingConsumersCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  }
}
