export const StylistAddressType = {
  BUSINESS: 'Business',
  RESIDENTIAL: 'Residential'
}

export const StylistCountry = {
  USA: 'United States',
  CAN: 'Canada'
}

export const StylistMobile = {
  YES: 'Yes',
  NO: 'No'
}

export const StylistHdCertified = {
  YES: 'Yes',
  NO: 'No'
}

export const StylistSalonType = {
  COMMISSION_SALARY: 'Commission/Salary',
  BOOTH_RENTER: 'Booth Renter',
  STUDIO: 'Studio',
  HOME: 'Home',
  ACADEMY_SCHOOL: 'Academy/School'
}

export const StylistsCertifications = {
  LB_HALF: 'lb-half',
  LB_FULL: 'lb-full',
  ML_REGULAR: 'ml-regular',
  ML_CUTTING: 'ml-cuting',
  FS_ONLINE: 'fs-online',
  FS_IN_PERSON: 'fs-in-person'
}

export const SettingsType = {
  CERTIFICATES: 'certificates',
  CERTIFICATES_CANADA: 'certificates_canada',
  SALON_HOME_PAGE: 'salon_home_page'
}

export const ApplicationTags = {
  NEW_ML: 'New Application: ML',
  NEW_LB: 'New Application: LB',
  NEW_FS: 'New Application: FS',
  NEW_TA: 'New Application: TA',
  LICENSE: 'License: needs review',
  DISTANCE: 'Distance: needs review',
  MESSAGE: 'Message: needs review',
  DUPLICATE_PHONE: 'Flag: Duplicate phone',
  DUPLICATE_ADDRESS: 'Flag: Duplicate address'
}

export const StylistProfession = {
  HAIRDRESSER_BARBER: 'Licensed Hairdresser/Barber',
  ESTHETICIAN: 'Licensed Esthetician',
  NAIL_TECH: 'Licensed Nail Tech',
  SALON_OWNER: 'Salon Owner/Co-Owner',
  SALON_MANAGER: 'Salon Manager',
  SALON_DIRECTOR: 'Salon Director',
  EDUCATOR_INSTRUCTOR: 'Educator/Instructor',
  COSMETOLOGY_STUDENT: 'Cosmetology Student'
}

export const CONSULTATION_ADDED_BY = {
  SALON: 'salon',
  CONSUMER: 'consumer',
  ADMIN: 'admin',
  REMOVED_BY_SALON: 'removed_by_salon'
}

export const APPLICATION_BOOKED_OPTIONS = {
  YES: 'yes',
  NO: 'no',
  NO_PRICE: 'no - price',
  NO_MAINTENANCE: 'no - maintenance',
  NO_RETHINKING: 'no - rethinking'
}

export const CONSULTATION_COMPLETED_OPTIONS = {
  YES: 'yes',
  NO: 'no',
  RESCHEDULED: 'rescheduled',
  NO_SHOW: 'no - no show',
  NO_RETHINKING: 'no - rethinking'
}

export const DEFERRED_EMAIL = {
  NO_ACT_24: 'noAct24',
  NO_ACT_48_DISCOUNT: 'noAct48Discount',
  FOLLOW_UP: 'follow up',
  DISCOUNT: 'discount',
  WF4H_CODE: 'WF4H_CODE',
  WF4S_CODE: 'WF4S_CODE'
}

export const CCPA_RequsetTypes = {
  account_info: 'Edit your account',
  data_portability_ccpa: 'Data Portability - CCPA requests',
  data_portability_personal: 'Data Portability - Personal Information',
  personal_data: 'Access to Personal Data',
  do_not_sell: 'Do not Sell My Personal Information',
  forgotten_right: 'Right to be Forgotten'
}

export const ConsumerSources = {
  salon_finder: 'salon_finder',
  contact_page: 'contact_page'
}

export const StylistsAccountSource = {
  contact_page: 'contact_page'
}

export const HighlightOptions = {
  FREE_CONSULTATION: { text: 'Free consultation', icon: 'FreeConsultation' },
  FREE_PARKING: { text: 'Free parking', icon: 'FreeParking' },
  VIRTUAL_ESTIMATES: { text: 'Virtual estimates', icon: 'VirtualEstimates' },
  COLOR_SERVICES: { text: 'Color services', icon: 'ColorServices' },
  BLOW_OUT_SERVICES: { text: 'Blow out services', icon: 'BlowOutServices' },
  HAIRCUT_SERVICES: { text: 'Haircut services', icon: 'HaircutServices' },
  HAIRDREAMS_EDUCATOR: {
    text: 'Hairdreams educator',
    icon: 'HaircutEducator'
  },
  HAIR_LOSS_SPECIALIST: {
    text: 'Hair loss specialist',
    icon: 'HairLossSpecialist'
  },
  HAIR_EXTENSIONS_SPECIALIST: {
    text: 'Hair extension specialist',
    icon: 'HairExtensionSpecialist'
  }
}

export const AdditionalHighlightOptions = {
  HAIRDREAMS_FLAGSHIP_SALON: {
    text: 'Hairdreams Flagship Salon',
    icon: 'mdi-star'
  },
  HAIRDREAMS_ELITE_SALON: {
    text: 'Hairdreams Elite Salon',
    icon: 'mdi-star'
  }
}

export const SystemOptions = {
  FREESTYLER: 'FreeStyler Strands by Hand Extensions',
  LASERBEAMER: 'Laserbeamer Nano Automatic Extensions',
  MICROLINES: 'MicroLines Hair Integration Pieces',
  TAPE: 'Tape In Extensions'
  // OTHER: 'Other'
}

export const AdditionalSystemOptions = {
  BONDED_EXTENSIONS: 'Bonded Extensions',
  EXTENSIONS: 'Extensions'
}

export const ShortSystemOptions = system => {
  switch (system) {
    case SystemOptions.MICROLINES:
      return 'MicroLines'
    case SystemOptions.LASERBEAMER:
      return 'Laserbeamer'
    case SystemOptions.TAPE:
      return 'Tape-ins'
    case SystemOptions.FREESTYLER:
      return 'FreeStyler'
    case AdditionalSystemOptions.BONDED_EXTENSIONS:
      return 'Hairdreams Bonded Ext.'
    default:
      return ''
  }
}

export const SystemOptionsShort = {
  FREESTYLER: 'freestyler',
  LASERBEAMER: 'nano',
  MICROLINES: 'microlines',
  TAPE: 'tapes'
}

export const getShortSystemForUrl = system => {
  switch (system) {
    case SystemOptions.LASERBEAMER:
      return SystemOptionsShort.LASERBEAMER
    case SystemOptions.FREESTYLER:
      return SystemOptionsShort.FREESTYLER
    case SystemOptions.TAPE:
      return SystemOptionsShort.TAPE
    case SystemOptions.MICROLINES:
      return SystemOptionsShort.MICROLINES
    default:
      break
  }
}

export const getFullSystemByUrl = shortSystem => {
  switch (shortSystem) {
    case SystemOptionsShort.LASERBEAMER:
      return SystemOptions.LASERBEAMER
    case SystemOptionsShort.FREESTYLER:
      return SystemOptions.FREESTYLER
    case SystemOptionsShort.TAPE:
      return SystemOptions.TAPE
    case SystemOptionsShort.MICROLINES:
      return SystemOptions.MICROLINES
    default:
      break
  }
}

export const NewSystemOptions = {
  MICROLINES: 'MicroLines Hair Integration Pieces',
  // 'FreeStyler Strands by Hand Extensions',
  // 'Laserbeamer Nano Automatic Extensions'
  BONDED_EXTENSIONS: 'Bonded Extensions',
  TAPE: 'Tape In Extensions',
  // all except MicroLines
  EXTENSIONS: 'Extensions'
}

export const SystemQuestionAnswers = {
  YES: 'Yes',
  NO: 'No',
  NOT_ASKED: 'Question not asked'
}

export const SystemQuestions = {
  [SystemOptions.MICROLINES]: [
    {
      question:
        'Does your book warrant a bi-monthly MicroLines maintenance services?',
      answers: {
        YES: { text: SystemQuestionAnswers.YES, value: 1 },
        NO: { text: SystemQuestionAnswers.NO, value: 2 },
        NOT_ASKED: { text: SystemQuestionAnswers.NOT_ASKED, value: 0 }
      }
    },
    {
      question: 'Should we remove you as a MicroLines referral salon?',
      answers: {
        YES: { text: SystemQuestionAnswers.YES, value: 2 },
        NO: { text: SystemQuestionAnswers.NO, value: 1 },
        NOT_ASKED: { text: SystemQuestionAnswers.NOT_ASKED, value: 0 }
      }
    }
  ],
  [SystemOptions.FREESTYLER]: [
    {
      question: `Are you open to new ${SystemOptions.FREESTYLER} clients?`,
      answers: {
        YES: { text: SystemQuestionAnswers.YES, value: 1 },
        NO: { text: SystemQuestionAnswers.NO, value: 2 },
        NOT_ASKED: { text: SystemQuestionAnswers.NOT_ASKED, value: 0 }
      }
    },
    {
      question: `Should we remove you as a ${SystemOptions.FREESTYLER} referral salon?`,
      answers: {
        YES: { text: SystemQuestionAnswers.YES, value: 2 },
        NO: { text: SystemQuestionAnswers.NO, value: 1 },
        NOT_ASKED: { text: SystemQuestionAnswers.NOT_ASKED, value: 0 }
      }
    }
  ],
  [SystemOptions.LASERBEAMER]: [
    {
      question: `Are you open to new ${SystemOptions.LASERBEAMER} clients?`,
      answers: {
        YES: { text: SystemQuestionAnswers.YES, value: 1 },
        NO: { text: SystemQuestionAnswers.NO, value: 2 },
        NOT_ASKED: { text: SystemQuestionAnswers.NOT_ASKED, value: 0 }
      }
    },
    {
      question: `Should we remove you as a ${SystemOptions.LASERBEAMER} referral salon?`,
      answers: {
        YES: { text: SystemQuestionAnswers.YES, value: 2 },
        NO: { text: SystemQuestionAnswers.NO, value: 1 },
        NOT_ASKED: { text: SystemQuestionAnswers.NOT_ASKED, value: 0 }
      }
    }
  ],
  [SystemOptions.TAPE]: [
    {
      question: `Are you open to new ${SystemOptions.TAPE} clients?`,
      answers: {
        YES: { text: SystemQuestionAnswers.YES, value: 2 },
        NO: { text: SystemQuestionAnswers.NO, value: 1 },
        NOT_ASKED: { text: SystemQuestionAnswers.NOT_ASKED, value: 0 }
      }
    },
    {
      question: `Should we remove you as a ${SystemOptions.TAPE} referral salon?`,
      answers: {
        YES: { text: SystemQuestionAnswers.YES, value: 2 },
        NO: { text: SystemQuestionAnswers.NO, value: 1 },
        NOT_ASKED: { text: SystemQuestionAnswers.NOT_ASKED, value: 0 }
      }
    }
  ]
}

export const ReviewTypes = {
  FB: 'FB',
  YELP: 'YELP',
  GOOGLE: 'GOOGLE',
  OTHER: 'OTHER'
}

export const ContactMessageConsumerTopics = {
  NO_SALON: 'No salon within preferred radius - Help me with alternatives',
  HD_DIDNT_CONTACT_ME: 'Hairdreams Specialist didn’t contact me',
  CONSULTATION: 'Consultation for pricing',
  CANT_REACH: "Salon didn't respond - need Hairdreams",
  OTHER: 'Other'
}

export const ContactMessageSalonTopics = {
  TERMS: 'Salon Terms and Conditions'
}

export const ContactMessageStylistTopics = {
  ONE_SYSTEM: "I'm interested in ONE system",
  MORE_THAN_ONE_SYSTEM: "I'm interested in more than ONE system",
  HAIRDREAMS_PARTNER: "I'm an existing Hairdreams partner",
  OTHER: 'Other non-product or non-system questions',
  DREAMWEFTS: 'DreamWefts',
  ULTRA: 'Ultra'
}

export const ContactMessageSources = {
  // (front)
  example_page: 'example_page',
  // (front)
  declined_terms: 'declined_terms',
  // (front)
  no_salon_found: 'no_salon_found',
  // (front)
  miles_100: '100_miles',
  // (front)
  salon_finder: 'salon_finder',
  // wf2.3 -> other -> contact (front)
  wf2_3_other: 'wf2_3_other',
  // iz mejla (back)
  wf2c_discount: 'wf2c_discount',
  // iz mejla (back)
  wf2c_followUp: 'wf2c_followUp'
}

export const ThankYouPageSources = {
  edit_salon: 'edit-salon',
  contact: 'contact'
}

export const MessageTypes = {
  C_S: 'c_s',
  C_H: 'c_h',
  S_H: 's_h',
  ST_H: 'st_h'
}

export const MessageFrom = {
  C: 'consumer',
  S: 'salon',
  HD: 'hairdreams',
  ST: 'stylist'
}

export const CallTypes = {
  C_S: 'c_s',
  C_H: 'c_h'
}

export const CallFrom = {
  C: 'consumer',
  S: 'salon',
  HD: 'hairdreams'
}

export const UserRoles = {
  // -> disable import salons
  // -> disable edit/delete salons
  // -> disable edit/delete users
  FULL: 'full',
  ADMIN_NO_STYLISTS_ACCESS: 'admin-no-stylists-access',
  SALON: 'salon',
  STYLIST: 'stylist'
}

export const SalonCountries = {
  USA: 'United States',
  CAN: 'Canada'
}

export const ApplicationStatus = {
  NOT_APPROVED: 'Not approved',
  PENDING: 'Pending',
  APPROVED: 'Approved',
  CONTINUE: 'Continue'
}

export const MLClass = {
  REG: 'ML Regular',
  CUT: 'ML Cut'
}

export const LBClass = {
  HALF: 'LB Half Head',
  FULL: 'LB Full Head'
}

export const FSClass = {
  ONLINE: 'FS Online',
  IN_PERSON: 'FS In Person'
}

export const SalonSourceRating = { A: 'A', B: 'B', C: 'C', S: 'S' }
